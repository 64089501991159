import { FC, useEffect } from 'react';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { classNames, quantityInputProps as _quantityInputProps } from '../../lib/utils';
import { IProduct } from '../../lib/fetch/products';
import { useAuth } from '../../providers/AuthProvider';
import { useDebouncedCallback } from 'use-debounce';
import { useCart } from '../../providers/CartProvider';

interface IAddCartField {
  onAdd: (quantity: number) => void;
  product: IProduct;
  setQuantity?: (quantity: number) => void;
}

const AddCartField: FC<IAddCartField> = (props) => {
  const [{ user }] = useAuth();
  const { addToCart } = useCart();

  const { product, setQuantity } = props;
  const quantityInputProps = _quantityInputProps(product, user);
  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      quantity: quantityInputProps.defaultValue,
    },
  });
  const quantity = watch('quantity');

  const debouncedSetQuantity = useDebouncedCallback((quantity: number) => {
    setQuantity && setQuantity(quantity || 0);
  }, 300);

  useEffect(() => {
    debouncedSetQuantity(quantity);
  }, [quantity, debouncedSetQuantity]);

  const disabled = quantityInputProps.disabled;
  const disabledByQuantity = disabled || quantity < 1;
  const onSubmit = () => {
    addToCart({ product, quantity, displayAddToCartModal: true });
  };

  return (
    <form className="flex items-center justify-end" onSubmit={handleSubmit(onSubmit)}>
      <input
        type="number"
        className={classNames(
          'mr-2 block w-20 rounded-md border-transparent bg-gray-100 py-2 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm min-w-16',
          disabled && 'opacity-50',
        )}
        required
        {...quantityInputProps}
        {...register('quantity', {
          required: true,
          disabled,
          valueAsNumber: true,
        })}
      />
      <button
        type="submit"
        disabled={disabledByQuantity}
        className={classNames(
          'flex items-center justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-normal text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2',
          !disabledByQuantity && 'hover:bg-red-700',
          disabledByQuantity && 'opacity-50',
        )}
        style={disabledByQuantity ? { cursor: 'not-allowed' } : {}}
      >
        <ShoppingCartIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </form>
  );
};

export default AddCartField;
