import { CheckIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { IAddress } from '../../../../../lib/interfaces';
import Address from './Address';
import DeleteAddress from './DeleteAddress';
import EditAddress from './EditAddress';

interface IAddressBox {
  address: IAddress;
}

const SimpleAddressBox = (props: IAddressBox) => {
  const { address } = props;
  const { title, deliveryDefault, billingDefault } = address;
  const { t } = useTranslation('PROFILE');

  return (
    <>
      <div className="rounded-lg border border-gray-300">
        {title && (
          <div className="border-b border-gray-300 p-3">
            <h3 className="text-md font-bold text-gray-800">{title}</h3>
          </div>
        )}
        <div className="px-3 py-4">
          <Address address={address} />

          {deliveryDefault && (
            <div className="mt-6 flex items-center">
              <div className="mr-2 flex h-6 w-6 items-center justify-center rounded-xl bg-red-600  text-white">
                <CheckIcon className="h-4 w-4" />
              </div>
              <p className="text-sm font-semibold text-gray-600">{t('DEFAULT_SHIPPING')}</p>
            </div>
          )}

          {billingDefault && (
            <div className="mt-6 flex items-center">
              <div className="mr-2 flex h-6 w-6 items-center justify-center rounded-xl bg-red-600  text-white">
                <CheckIcon className="h-4 w-4" />
              </div>
              <p className="text-sm font-semibold text-gray-600">{t('DEFAULT_BILLING')}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SimpleAddressBox;
