import { useQuery } from 'react-query';
import { useAlert } from '../../providers/AlertProvider';
import { useAuth } from '../../providers/AuthProvider';
import FullScreenLoading from '../../components/FullScreenLoading';
import { useTranslation } from 'react-i18next';
import MessageAlert from '../../components/Alert/MessageAlert';
import i18n from '../../i18n';
import { Sidebar } from '../../components/Sidebar';
import { fetchPurchasedProducts } from '../../lib/fetch/purchasedProducts';
import { ITab, TabBarBig } from '../../components/TabBars';
import { StringParam, useQueryParams } from 'use-query-params';
import PurchasedProductsTable from './PurchasedProductsTable';
import { PurchasesType } from '../../lib/fetch/orderItems';

const PurchasedProducts = () => {
  const [{ token }] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('PURCHASED_PRODUCTS');

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });

  const onSuccess = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onError = () => {
    dispatchAlertChange({ open: true });
  };

  const {
    data: ownPurchasedProducts,
    isError: isOwnPurchasedProductsError,
    isLoading: isOwnPurchasedProductsLoading,
  } = useQuery(
    ['ownPurchasedItemsList', i18n.languages],
    () =>
      fetchPurchasedProducts(token, {
        page: 1,
        limit: 100,
        sort: ['date:desc'],
        filters: { purchasesType: PurchasesType.own },
      }),
    {
      onSuccess,
      onError,
    },
  );

  const {
    data: customerPurchasedProducts,
    isError: isCustomerPurchasedProductsError,
    isLoading: isCustomerPurchasedProductsLoading,
  } = useQuery(
    ['customerCurchasedItemsList', i18n.languages],
    () =>
      fetchPurchasedProducts(token, {
        page: 1,
        limit: 100,
        sort: ['date:desc'],
        filters: { purchasesType: PurchasesType.customer },
      }),
    {
      onSuccess,
      onError,
    },
  );

  const tabs: ITab[] = [
    {
      name: t('PURCHASED_BY_ME'),
      count: ownPurchasedProducts?.data.length,
      current: query.tab === PurchasesType.own,
      onClick: () => {
        setQuery({ tab: PurchasesType.own });
      },
    },
    {
      name: t('PURCHASED_BY_OTHERS'),
      count: customerPurchasedProducts?.data.length,
      current: query.tab === PurchasesType.customer,
      onClick: () => {
        setQuery({ tab: PurchasesType.customer });
      },
    },
  ];

  return (
    <div>
      {isOwnPurchasedProductsError ||
        (isCustomerPurchasedProductsError && (
          <div className="h-full py-6 px-4 lg:pr-0">
            <div className="mx-auto max-w-xl text-center">
              <MessageAlert text={t('GENERIC_ERROR')} />
            </div>
          </div>
        ))}
      <div className="mx-auto w-full px-4">
        <div className="min-h-screen min-w-0 flex-1 bg-white lg:flex">
          {isOwnPurchasedProductsLoading ||
            (isCustomerPurchasedProductsLoading && (
              <FullScreenLoading loading title={t('LOADING')} subtitle={t('LOADING_SUBTITLE')} />
            ))}

          <div className="flex w-full">
            <Sidebar />
            <div className=" flex w-full flex-col p-4 m-2">
              <TabBarBig tabs={tabs} />
              {ownPurchasedProducts && query.tab === PurchasesType.own && (
                <PurchasedProductsTable purchasedProducts={ownPurchasedProducts} />
              )}
              {customerPurchasedProducts && query.tab === PurchasesType.customer && (
                <PurchasedProductsTable purchasedProducts={customerPurchasedProducts} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchasedProducts;
