import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BooleanParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { OrderStatus, PaymentMethod } from '../../lib/enums';
import { me } from '../../lib/fetch/auth';
import { create as createOrder, getOne as getOrder, IOrder } from '../../lib/fetch/orders';
import CheckoutPaymentPresentational from './CheckoutPaymentPresentational';
import { createIntent } from '../../lib/fetch/payments';
import { IWireTransferDataAttributes, list as getWireTransferData } from '../../lib/fetch/wireTransfer';
import { calculateCartTotals, flushCart } from '../../lib/utils/cart';
import { createOrderItem } from '../../lib/utils/order';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import { useSettings } from '../../providers/SettingsProvider';
import { useCart } from '../../providers/CartProvider';

const CheckoutPayment = () => {
  const [{ token, user }, dispatchAuthChange] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const [{ tecdocManufacturers }] = useSettings();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('SHOPPING_CART');
  const [lang] = i18n.languages;
  const [locale] = lang.split('-');
  const {
    cartState: { cart },
  } = useCart();
  const { id: userId, addresses } = user!;
  const { cart_items = [], shipping: cartShipping } = cart || {};
  const [loading, setLoading] = useState(false);
  const [loadingWT, setLoadingWT] = useState(false);
  const [order, setOrder] = useState<IOrder | undefined>();
  const [wireTransferData, setWireTransferData] = useState<IWireTransferDataAttributes | undefined>();
  const { fetchCart } = useCart();

  const [query] = useQueryParams({
    billingAddressId: NumberParam,
    deliveryAddressId: NumberParam,
    deliveryType: StringParam,
    noteValue: StringParam,
    disableWireTransfer: BooleanParam,
    orderId: NumberParam,
  });
  const { billingAddressId, deliveryAddressId, deliveryType, noteValue, disableWireTransfer, orderId } = query || {};
  const selectedForOrderCartItems = cart_items.filter((cartItem) => cartItem.selectedForOrder);
  useEffect(() => {
    if (!orderId) {
      setOrder(undefined);
    } else {
      fetchOrder(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    if (disableWireTransfer) return;
    (async () => {
      setLoadingWT(true);
      const { data: { data } = {}, error } = await getWireTransferData(token!, locale);
      setLoadingWT(false);
      if (!error && data?.attributes) setWireTransferData(data.attributes);
    })();
  }, [disableWireTransfer]);

  const fetchUser = async () => {
    setLoading(true);
    const { error: meError, data: updatedUser } = await me(token!, lang);
    !meError &&
      updatedUser &&
      dispatchAuthChange({
        type: AuthActionType.SetUser,
        user: updatedUser,
      });
    setLoading(false);
  };

  const fetchOrder = async (orderId: number) => {
    setLoading(true);
    const { data: { data } = {}, error } = await getOrder(token!, orderId);
    setLoading(false);
    if (error || !data) return dispatchAlertChange({ open: true });

    setOrder(data);
  };

  const createCardPaymentIntent = async (orderId: number, paymentMethod: PaymentMethod) => {
    const createIntentRes = await createIntent(token!, {
      orderId: orderId!,
      paymentMethod,
    });
    setLoading(false);
    if (createIntentRes.error) {
      return navigate(
        `/checkout-result?${queryString.stringify({
          orderId,
          success: 0,
        })}`,
      );
    }
    const { redirectUrl } = createIntentRes.data;
    window.location.href = redirectUrl;
  };

  const createWireTransferPayment = async (
    orderId: number,
    paymentData?: { wireTransferData?: IWireTransferDataAttributes },
  ) => {
    /*     const { error } = await createPayment(token!, {
      date: new Date(),
      order: orderId,
      paymentData: JSON.stringify(paymentData || {}, null, 2),
      provider: PaymentMethod.WireTransfer,
      status: PaymentStatus.Processing,
      users_permissions_user: userId,
    }); */
    const error = false;
    await fetchUser();
    navigate(
      `/checkout-result?${queryString.stringify({
        orderId,
        success: error ? 0 : 1,
        wireTransfer: 1,
      })}`,
    );
  };

  const onCheckoutProceed = async (
    paymentMethod: PaymentMethod,
    paymentData?: { wireTransferData?: IWireTransferDataAttributes },
  ) => {
    setLoading(true);
    let orderId = order?.id;

    if (!orderId) {
      const {
        totalDiscount: discount,
        totalTaxes: taxes,
        totalShipping: shipping,
        subTotalNet: subtotal,
        total,
      } = calculateCartTotals(selectedForOrderCartItems, cartShipping || 0);

      if (total <= 0) {
        setLoading(false);
        return dispatchAlertChange({
          open: true,
          type: AlertType.Error,
          message: t('ZERO_TOTAL_ERROR'),
        });
      }

      //create order items
      let orderItemIds = [];

      for (let cartItem of selectedForOrderCartItems) {
        const createOrderItemRes = await createOrderItem(token!, lang, cartItem, tecdocManufacturers);
        if (createOrderItemRes.error || !createOrderItemRes.data) {
          setLoading(false);
          return dispatchAlertChange({ open: true });
        }

        const {
          data: { id: orderItemId },
        } = createOrderItemRes.data;
        orderItemIds.push(orderItemId);
      }

      // Create Order Addresses
      const orderBillingAddress = addresses.find(({ id }) => id === billingAddressId);
      const orderShippingAddress = addresses.find(({ id }) => id === deliveryAddressId);
      /*
      if (!orderBillingAddress || !orderShippingAddress) {
        setLoading(false);
        return dispatchAlertChange({ open: true });
      }
      const [createdBillingAddressRes, createdShippingAddressRes] = await Promise.all([
        createOrderBillingAddress(token!, {
          ...pick(orderBillingAddress, ADDRESS_COMMON_PROPS),
          users_permissions_user: userId,
        }),
        createOrderShippingAddress(token!, {
          ...pick(orderShippingAddress, ADDRESS_COMMON_PROPS),
          users_permissions_user: userId,
        }),
      ]);
      if (
        createdBillingAddressRes.error ||
        createdShippingAddressRes.error ||
        !createdBillingAddressRes.data ||
        !createdShippingAddressRes.data
      ) {
        setLoading(false);
        return dispatchAlertChange({ open: true });
      }
        */

      //createOrder
      const createOrderRes = await createOrder(
        token!,
        {
          users_permissions_user: userId,
          customer_id: user?.customer.customer_id,
          customer_attivo: user?.customer.status || 0,
          date: new Date(),
          billingAddressId: billingAddressId!,
          deliveryAddressId: deliveryAddressId!,
          ord_bill_address: Number(orderBillingAddress?.number), // createdBillingAddressRes.data.data.id,
          ord_ship_address: Number(orderShippingAddress?.number), // createdShippingAddressRes.data.data.id,
          deliveryType: deliveryType,
          note: noteValue,
          subtotal,
          discount,
          taxes,
          shipping,
          total,
          status: OrderStatus.NotPaid,
          order_items: orderItemIds,
          shipping_address: addresses[0],
        },
        lang,
      );

      if (createOrderRes.error || !createOrderRes.data || createOrderRes.data.meta.status !== 201) {
        navigate(`/checkout-result?${queryString.stringify({ success: 0 })}`);
        return;
      }

      /*       if (createOrderRes.error || !createOrderRes.data) {
        const hasPolicyFailed = createOrderRes.error && createOrderRes.error.status === 403;
        if (hasPolicyFailed) await fetchUser();
        const alertAction: AlertAction = {
          open: true,
          type: hasPolicyFailed ? AlertType.Info : AlertType.Error,
          message: hasPolicyFailed ? t('ORDER_CREATION_POLICY_ERROR') : undefined,
        };
        setLoading(false);
        return dispatchAlertChange(alertAction);
      } */

      const {
        data: { id: createdOrderId },
      } = createOrderRes.data;
      orderId = createdOrderId;

      await flushCart(token!, user!, cart!);
      fetchCart();
    }

    switch (paymentMethod) {
      case PaymentMethod.Paypal:
        setLoading(false);
        return { orderId };
      case PaymentMethod.Card:
        createCardPaymentIntent(orderId!, paymentMethod);
        break;
      case PaymentMethod.WireTransfer:
        createWireTransferPayment(orderId!, paymentData);
        break;
      default:
        break;
    }
  };

  const onPayPalError = async () => {
    await fetchUser();
    navigate(`/checkout-result?${queryString.stringify({ success: 0 })}`);
  };

  const onPayPalSuccess = async (orderId?: string) => {
    await fetchUser();
    navigate(
      `/checkout-result?${queryString.stringify({
        orderId,
        success: 1,
      })}`,
    );
  };

  return (
    <CheckoutPaymentPresentational
      addresses={addresses}
      cart={cart}
      deliveryAddressId={deliveryAddressId}
      loading={loading || loadingWT}
      onCheckoutProceed={onCheckoutProceed}
      onPayPalError={onPayPalError}
      onPayPalSuccess={onPayPalSuccess}
      order={order}
      wireTransferData={wireTransferData}
    />
  );
};

export default CheckoutPayment;
