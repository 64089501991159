import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface FiltersProps {
  filters: {
    customer_id: string;
    name: string;
    vat_num: string;
    active: string;
    address: string;
  };
  onFilterChange: (filterName: string, value: string) => void;
}

const Filters: React.FC<FiltersProps> = ({ filters, onFilterChange }) => {
  const { t } = useTranslation('CUSTOMER_LIST');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    onFilterChange(e.target.name, e.target.value);
  };

  const filterField = (name: any, value: any, placeHolder: any) => (
    <>
      <input
        type="text"
        name={name}
        value={value}
        onChange={handleInputChange}
        placeholder={placeHolder}
        className="bg-gray-100 text-black rounded-lg py-1 px-3 border-none"
      />
    </>
  );

  return (
    <div className="flex items-center my-4 justify-end">
      <span className="text-gray-500 mr-2">{t('FILTER_BY')}</span>
      <div className="flex space-x-2">
        {filterField('customer_id', filters.customer_id, t('CODE'))}
        {filterField('name', filters.name, t('BUSINESS_NAME'))}
        {filterField('address', filters.address, t('ADDRESS'))}
        {filterField('vat_num', filters.vat_num, t('PIVA_FISCAL_CODE'))}
        {/*<input
          type="text"
          name="name"
          value={filters.name}
          onChange={handleInputChange}
          placeholder="Business name"
          className="bg-gray-100 text-black rounded-lg py-1 px-3"
        />
        <input
          type="text"
          name="vat_num"
          value={filters.vat_num}
          onChange={handleInputChange}
          placeholder="P.IVA / Fiscal code"
          className="bg-gray-100 text-black rounded-lg py-1 px-3"
  />*/}
        <div className="bg-gray-100 text-black rounded-lg px-1 flex items-center">
          <select
            name="active"
            value={filters.active}
            onChange={handleInputChange}
            className="bg-transparent outline-none border-none"
          >
            <option value="">{t('STATE')}</option>
            <option value="true">{t('ACTIVE')}</option>
            <option value="false">{t('INACTIVE')}</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Filters;
