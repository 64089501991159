import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UrlUpdateType } from 'use-query-params';
import { IMyOrdersQuery } from '.';
import MessageAlert from '../../components/Alert/MessageAlert';
import FullScreenLoading from '../../components/FullScreenLoading';
import MyOrdersFilter from '../../components/ProductsTable/MyOrdersFilter';
import OrdersTable, { Pagination } from '../../components/ProductsTable/OrdersTable';
import { ITab, TabBarBig } from '../../components/TabBars';
import { IOrder } from '../../lib/fetch/orders';
import Searching from '../../lotties/searching.json';
import { ORDER_CANCELED_STATUSES, ORDER_COMPLETED_STATUSES, ORDER_PROCESSING_STATUSES } from '../../lib/constants';

interface IMyOrdersPresentational {
  loading: boolean;
  countLoading: boolean;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  orders: IOrder[];
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
  totalsByStatus: {
    all: number;
    processing: number;
    canceled: number;
    completed: number;
  };
  query: IMyOrdersQuery;
  onChangeQuery: (query: IMyOrdersQuery, urlUpdateType?: UrlUpdateType) => void;
}

const MyOrdersPresentational: FC<IMyOrdersPresentational> = (props) => {
  const {
    loading = false,
    countLoading = false,
    onPageChange = () => {},
    onPageSizeChange = () => {},
    orders = [],
    page = 1,
    pageCount = 1,
    pageSize = 0,
    total = 0,
    totalsByStatus,
    query = {},
    onChangeQuery = () => {},
  } = props;

  const { t } = useTranslation('MY_ORDERS');

  const tabs: ITab[] = [
    {
      name: t('TAB_ALL'),
      count: totalsByStatus.all,
      current: !query.statuses,
      onClick: () => onChangeQuery({ statuses: undefined }),
    },
    {
      name: t('TAB_PROCESSING'),
      count: totalsByStatus.processing,
      current: !!query.statuses && query.statuses.some((status) => ORDER_PROCESSING_STATUSES.includes(status)),
      onClick: () =>
        onChangeQuery({
          statuses: ORDER_PROCESSING_STATUSES,
        }),
    },
    {
      name: t('TAB_CANCELED'),
      count: totalsByStatus.canceled,
      current: !!query.statuses && query.statuses.some((status) => ORDER_CANCELED_STATUSES.includes(status)),
      onClick: () => onChangeQuery({ statuses: ORDER_CANCELED_STATUSES }),
    },
    {
      name: t('TAB_COMPLETED'),
      count: totalsByStatus.completed,
      current: !!query.statuses && query.statuses.some((status) => ORDER_COMPLETED_STATUSES.includes(status)),
      onClick: () => onChangeQuery({ statuses: ORDER_COMPLETED_STATUSES }),
    },
  ];

  return (
    <div className="mx-auto w-full flex-grow lg:flex lg:px-4">
      <div className="min-h-screen min-w-0 flex-1 bg-white lg:flex">
        <div className="bg-white lg:w-64 lg:flex-shrink-0 xl:w-72">
          <div className="relative h-full pl-4 pr-4 pt-6 pb-0 sm:pl-4 lg:pb-6 lg:pl-0 lg:pr-0">
            <div className="h-full lg:sticky lg:top-0 lg:w-64 xl:w-72 overflow-y-auto max-h-screen">
              <div className="mb-6 border-b border-gray-200 pb-2 sticky top-0 bg-white z-10">
                <h2 className="text-xl font-bold text-gray-900">{t('MENU:MY_ORDERS')}</h2>
              </div>
              <MyOrdersFilter count={totalsByStatus.all} query={query} onChangeQuery={onChangeQuery} />
            </div>
          </div>
        </div>

        <div className="bg-white lg:min-w-0 lg:flex-1">
          <div className="h-full py-6 px-4 lg:pr-0">
            <TabBarBig tabs={tabs} />

            {!loading && !orders?.length && (
              <div className="mx-auto mt-6 max-w-xl text-center">
                <MessageAlert text={t('NO_RESULT')} />
              </div>
            )}

            <FullScreenLoading
              loading={loading || countLoading}
              lottie={Searching}
              title={t('SEARCH_IN_PROGRESS_TITLE')}
              subtitle={t('SEARCH_IN_PROGRESS_SUBTITLE')}
              height={200}
              width={400}
            />

            {orders.length ? (
              <>
                <div className="mt-6 overflow-auto" style={{ height: '40rem' }}>
                  <OrdersTable orders={orders} />
                </div>
                <div className="sticky bottom-0">
                  <Pagination
                    currentItems={orders}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    page={page}
                    pageCount={pageCount}
                    pageSize={pageSize}
                    total={total}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrdersPresentational;
