import { IAddress } from '../../interfaces';
import IAgent from '../../interfaces/IAgent';
import ICustomer from '../../interfaces/ICustomer';
import { ICartItem } from '../cartItems';
export { default as create, type ICartCreate } from './create';
export { default as update, type ICartUpdate } from './update';

export interface ICart {
  id: number;
  status: CartStatus;
  cart_items: ICartItem[];
  shipping: number;
  shipping_address?: IAddress | null;
  agent?: IAgent | null;
  messages?: string[] | null;
  customer?: ICustomer;
}

export enum CartStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
