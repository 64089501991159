import { ROUTE_CONFIG } from '../../routes';

export enum ELEMENT_TYPE {
  HEADER = 'header',
  FOOTER = 'footer',
}

export const shouldShowElementInRoute = (path: string, element: ELEMENT_TYPE): boolean => {
  const route = ROUTE_CONFIG[path as keyof typeof ROUTE_CONFIG];
  return route ? route[element] : true;
};
