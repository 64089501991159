import axios, { AxiosResponse } from 'axios';
import { ICartItemCreate, ICartItemCreateResult } from './index';

const { REACT_APP_CART_ITEMS_SELECT_ALL_URL } = process.env;

export interface ICartItemUpdate extends ICartItemCreate {
  id: number;
}

export interface ICartItemUpdateResult extends ICartItemCreateResult {}

const axiosInstance = axios.create();

const selectAllForOrder = async (token: string, cartId: number, selectedForOrder: boolean) => {
  const response = await axiosInstance.post<{ data: ICartItemUpdateResult }>(
    `${REACT_APP_CART_ITEMS_SELECT_ALL_URL!}`,
    {
      cartId,
      selectedForOrder,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default selectAllForOrder;
