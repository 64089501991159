import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Addresses,
  CookiePolicy,
  Documents,
  GeneralData,
  PrivacyPolicy,
  CommercialStats,
  ReturnInfo,
  TermsConditions,
  VerticalNavigationProfile,
  CreditsLogs,
} from './components';
import { IStrapiFile, IUser } from '../../lib/interfaces';
import { IFrameBannerProfileSide } from '../../components/IFrameBanner/ProfileSide';
import Security from './components/Security';
import UserNotifications from './components/UserNotifications';
interface IProfilePresentational {
  acceptedFiles: readonly string[];
  files: IStrapiFile[];
  user: IUser;
  loading: boolean;
  onFileChange: (_: File[]) => void;
  onFileDelete: (_: IStrapiFile) => void;
}

const ProfilePresentational = (props: IProfilePresentational) => {
  const { t } = useTranslation('PROFILE');
  const { acceptedFiles, files, user, loading, onFileChange, onFileDelete } = props;
  return (
    <div>
      <div className="mx-auto w-full flex-grow lg:flex lg:px-4">
        <div className="min-h-screen min-w-0 flex-1 bg-white lg:flex">
          <div className="bg-white lg:w-64 lg:flex-shrink-0 xl:w-72">
            <div className="relative h-full pl-4 pr-4 pt-6 pb-0 sm:pl-4 lg:pb-6 lg:pl-0 lg:pr-0">
              <div className="h-full lg:sticky lg:top-0 lg:w-64 xl:w-72 overflow-y-auto max-h-screen">
                <div className="mb-6 border-b border-gray-200 pb-2 sticky top-0 bg-white z-10">
                  <h2 className="text-xl font-bold text-gray-900">{t('TITLE')}</h2>
                </div>
                <VerticalNavigationProfile />
                <div className="mt-10">
                  <IFrameBannerProfileSide />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 overflow-auto w-full" style={{ height: '40rem' }}>
            <Routes>
              <Route
                index
                element={
                  <GeneralData
                    acceptedFiles={acceptedFiles}
                    files={files}
                    user={user}
                    loading={loading}
                    onFileChange={onFileChange}
                    onFileDelete={onFileDelete}
                  />
                }
              />
              <Route path="addresses" element={<Addresses />} />
              <Route path="return-info" element={<ReturnInfo />} />
              <Route path="documents" element={<Documents />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="commercial-stats" element={<CommercialStats />} />
              <Route path="cookie-policy" element={<CookiePolicy />} />
              <Route path="security" element={<Security />} />
              <Route path="notifications" element={<UserNotifications />} />
              <Route path="terms-conditions" element={<TermsConditions />} />
              <Route path="credits-logs" element={<CreditsLogs />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePresentational;
