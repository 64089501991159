import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MagnifyingGlassIcon,
  ShoppingCartIcon,
  ArrowDownTrayIcon,
  AdjustmentsVerticalIcon,
} from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { WhereToSearch } from '../../../lib/enums';
import { ISearchParams } from './index';
import ImportModal from '../../Import';
import AdvanceSearchModal from '../../AdvanceSearch';
import { useAuth } from '../../../providers/AuthProvider';

interface ISearchHeaderPresentational {
  onSearch: (searchParams: ISearchParams) => void;
  search: string;
  brand: string;
  where: string;
}

const SearchHeaderPresentational: FC<ISearchHeaderPresentational> = (props) => {
  const [{ user }] = useAuth();
  const { onSearch = () => {}, search, brand, where } = props;
  const [showImportModal, setShowImportModal] = useState(false);
  const [showAdvanceSearchModal, setShowAdvanceSearchModal] = useState(false);
  const [firstRenderAdvanceSearch, setFirstRenderAdvanceSearch] = useState(true);

  const [whereType, setWhereType] = useState<WhereToSearch>((where as WhereToSearch) || WhereToSearch.Code);
  const { t } = useTranslation('MENU');
  const {
    handleSubmit,
    register,
    setValue: setFormValue,
  } = useForm({
    defaultValues: {
      addToCart: false,
      search,
      brand,
      where: WhereToSearch.Code,
    },
  });

  const advanceSearchEnabled = user?.aastoreEnabled && user?.customer?.aastoreEnabled;

  return (
    <form className="flex flex-wrap items-center" onSubmit={handleSubmit(onSearch)}>
      <div className="relative mr-2 rounded-md grow min-w-[200px]">
        <div className="mt-1 flex rounded-md shadow-sm">
          <span className="items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-0 text-gray-500 inline-flex">
            <select
              id="where"
              autoComplete="off"
              className="h-full min-w-[100px] rounded-md border-transparent bg-transparent py-0 pl-3 pr-7 text-xs text-gray-500 outline-none focus:outline-none focus:border-gray-500"
              value={whereType}
              {...register('where')}
              onChange={(e) => {
                setWhereType(e.target.value as WhereToSearch);
              }}
            >
              <option value={WhereToSearch.Code}>{t('CODE')}</option>
              <option value={WhereToSearch.PartialCode}>{t('PARTIAL_CODE')}</option>
              <option value={WhereToSearch.OemCode}>{t('OEM_CODE')}</option>
              <option value={WhereToSearch.Description}>{t('DESCRIPTION')}</option>
            </select>
          </span>
          <input
            type="text"
            id="search"
            className="block w-full min-w-[75px] flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 text-sm focus:border-gray-500 focus:ring-gray-500"
            placeholder={t('COMMON:SEARCH')}
            {...register('search')}
            onChange={(e) => {
              setFormValue('search', e.target.value);
            }}
          />
        </div>
      </div>

      {/* Button Section */}
      <div className="flex flex-wrap justify-center w-full mt-1 lg:mt-1 lg:flex-nowrap lg:items-center lg:justify-start lg:w-auto">
        <div className="mr-2">
          <button
            type="submit"
            className="flex w-full items-center justify-center h-9 rounded-full border border-transparent bg-red-600 py-2 px-3 text-xs font-normal text-white shadow-sm hover:bg-red-700 focus:outline-none lg:w-auto lg:rounded-md lg:px-4"
            onClick={() => setFormValue('addToCart', false)}
          >
            <MagnifyingGlassIcon className="-ml-0.5 h-4 w-4 lg:mr-2" aria-hidden="true" />
            <span className="hidden sm:inline-block">{t('SEARCH')}</span>
          </button>
        </div>

        <div className="mr-2">
          <button
            type="submit"
            className={`flex w-full items-center justify-center h-9 rounded-full border border-transparent py-2 px-3 text-xs font-normal text-white shadow-sm lg:w-auto lg:rounded-md lg:px-4 ${
              whereType !== WhereToSearch.Code ? 'bg-red-800 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
            }`}
            onClick={() => setFormValue('addToCart', true)}
            disabled={whereType !== WhereToSearch.Code}
          >
            <ShoppingCartIcon className="-ml-0.5 h-4 w-4 lg:mr-2" aria-hidden="true" />
            <span className="hidden sm:inline-block">{t('ADD_TO_CART')}</span>
          </button>
        </div>

        <div className="mr-2">
          <button
            type="button"
            className="flex w-full items-center justify-center h-9 rounded-full border border-transparent bg-red-600 py-2 px-3 text-xs font-normal text-white shadow-sm hover:bg-red-700 focus:outline-none lg:w-auto lg:rounded-md lg:px-4"
            onClick={() => setShowImportModal(true)}
          >
            <ArrowDownTrayIcon className="-ml-0.5 h-4 w-4 lg:mr-2" aria-hidden="true" />
            <span className="hidden sm:inline-block">{t('IMPORT')}</span>
          </button>
          <ImportModal open={showImportModal} onClose={() => setShowImportModal(false)} />
        </div>

        <div>
          <button
            type="button"
            className={`flex w-full items-center justify-center h-9 rounded-full border border-transparent bg-red-600 py-2 px-3 text-xs font-normal text-white shadow-sm hover:bg-red-700 focus:outline-none lg:w-auto lg:rounded-md lg:px-4 
                ${!advanceSearchEnabled ? 'bg-red-800 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'}
                `}
            disabled={!advanceSearchEnabled}
            onClick={() => setShowAdvanceSearchModal(true)}
          >
            <AdjustmentsVerticalIcon className="-ml-0.5 h-4 w-4 lg:mr-2" aria-hidden="true" />
            <span className="hidden sm:inline-block">{t('ADVANCE_SEARCH')}</span>
          </button>
          <AdvanceSearchModal
            open={showAdvanceSearchModal}
            onClose={() => setShowAdvanceSearchModal(false)}
            firstRenderAdvanceSearch={firstRenderAdvanceSearch}
            setFirstRenderAdvanceSearch={setFirstRenderAdvanceSearch}
          />
        </div>
      </div>
    </form>
  );
};

export default SearchHeaderPresentational;
