import { ISofinnResetPasswordForm } from '../../../lib/interfaces/ISofinn';
import SofinnUpdateProfileForm from './SofinnResetPasswordForm';

interface Props {
  onResetPassword: (values: ISofinnResetPasswordForm) => void;
  onRedirect: () => void;
}

const SofinnResetPasswordPresentational: React.FC<Props> = (props) => {
  const { onResetPassword, onRedirect } = props;
  return (
    <div className="h-screen flex flex-col justify-between">
      <div className="flex w-full flex-col justify-center align-middle">
        <div className="flex w-full px-[70px] pt-[30px]">
          <img className="flex w-[110px] h-[110px]" src="/images/sofinn/logo-cati-sofinn.png" alt="logo" />
        </div>
        <SofinnUpdateProfileForm onRedirect={onRedirect} onResetPassword={onResetPassword} />
      </div>
    </div>
  );
};

export default SofinnResetPasswordPresentational;
