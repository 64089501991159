import { useTranslation } from 'react-i18next';
import { ICategorySearchCount } from '../../lib/fetch/products/search';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { getMediaUrl } from '../../lib/utils';

interface CategorySearchProps {
  categories: ICategorySearchCount[];
}

export const CategorySearch = ({ categories }: CategorySearchProps) => {
  const { t } = useTranslation('PRODUCTS');
  const navigate = useNavigate();

  const handleCategoryClick = (category: ICategorySearchCount) => {
    navigate(
      `${ROUTES.SEARCH.SEARCH_RESULTS}?where=categories&categoryId=${category.id}&categoryName=${category.title}`,
    );
  };

  return (
    <div className="mb-8">
      <div className="font-bold text-2xl text-[#1A202C] mb-4">{t('CATEGORY_SEARCH')}</div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {categories.map((category) => (
          <button
            key={category.title}
            onClick={() => handleCategoryClick(category)}
            className="flex items-center bg-white rounded-lg shadow-md hover:bg-gray-100 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-gray-200"
          >
            <div className="w-24 h-24 bg-gray-200 rounded-lg flex items-center justify-center">
              <img src={getMediaUrl(category.image)} alt={category.title} className="w-24 h-24 object-cover rounded" />
            </div>

            <div className="ml-4 flex-1 text-left">
              <div className="font-bold text-[#1A202C] text-xl">{category.title}</div>
              <div className="w-fit text-xs text-gray-900 rounded-2xl	bg-slate-100 font-medium px-2 py-1">
                {category.count}
              </div>
            </div>

            <ChevronRightIcon className="h-5 w-5 text-gray-400 mr-4" />
          </button>
        ))}
      </div>
    </div>
  );
};
