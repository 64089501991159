import { createContext, useContext } from 'react';
import useCartUtilities, { INITIAL_CART_STATE } from '../../hooks/useCartUtilities';
import AddToCartModal from '../../components/Modals/AddToCartModal';
import StockExceededModal from '../../components/Modals/StockExceededModal';
import FullScreenLoading from '../../components/FullScreenLoading';
import ShoppingCartLottie from '../../lotties/shopping-cart.json';
import { useTranslation } from 'react-i18next';
import { ICartContext, SelectAllForOrderParams } from './interfaces';
import FreeItemsDialog from '../../components/Header/ShoppingCart/FreeItemsDialog';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { ICartItemUpdateResult } from '../../lib/fetch/cartItems';

const INITIAL_CART_CONTEXT: ICartContext = {
  cartState: INITIAL_CART_STATE,
  dispatchState: () => {},
  addToCart: async () => {},
  fetchCart: async () => {},
  onAddAll: async () => {},
  onFlushCart: async () => {},
  onItemRemove: async () => {},
  onItemQuantityChange: async () => {},
  dispatchOverlayChange: () => {},
  isOverlayOpen: false,
  onSearchAddToCart: async () => {},
  onExcerptChange: async () => {},
  onSelectedForOderChange: async () => {},
  flushCartWithConfirm: async () => {},
  selectAllForOrder: async (params: SelectAllForOrderParams) => ({
    data: { data: {} as ICartItemUpdateResult },
  }),
  isOverlayEnabled: true,
  setIsOverlayEnabled: () => {},
};

const CartContext = createContext<ICartContext>(INITIAL_CART_CONTEXT);

const CartProvider = ({ children }: { children: React.ReactElement }) => {
  const { t } = useTranslation();

  const {
    onAddToCart,
    isInCartModalOpen,
    toggleIsInCartModal,
    handleConfirm,
    onAddAll,
    currentProduct,
    currentQuantity,
    isStocExceededModalOpen,
    toggleStocExceededModalOpen,
    productStock,
    onAddToCartStockExceeded,
    cartCurrentQuantity,
    fetchCart,
    cartState,
    dispatchStateCart,
    onFlushCart,
    onItemRemove,
    onItemQuantityChange,
    dispatchOverlayChange,
    isOverlayOpen,
    freeCartItem,
    onSearchAddToCart,
    onExcerptChange,
    onSelectedForOderChange,
    toggleFlushCartModalOpen,
    isFlushCartModalOpen,
    flushCartWithConfirm,
    selectAllForOrder,
    isOverlayEnabled,
    setIsOverlayEnabled,
  } = useCartUtilities();

  return (
    <CartContext.Provider
      value={{
        cartState,
        dispatchState: dispatchStateCart,
        addToCart: onAddToCart,
        fetchCart,
        onAddAll,
        onFlushCart,
        onItemRemove,
        onItemQuantityChange,
        dispatchOverlayChange,
        isOverlayOpen,
        onSearchAddToCart,
        onExcerptChange,
        onSelectedForOderChange,
        flushCartWithConfirm,
        selectAllForOrder,
        isOverlayEnabled,
        setIsOverlayEnabled,
      }}
    >
      {children}
      <AddToCartModal
        isOpen={isInCartModalOpen}
        onRequestClose={toggleIsInCartModal}
        onConfirm={handleConfirm}
        product={currentProduct}
        quantity={currentQuantity}
      />
      {productStock && currentProduct && (
        <StockExceededModal
          toggleModal={toggleStocExceededModalOpen}
          onAddToCart={onAddToCartStockExceeded}
          isOpen={isStocExceededModalOpen}
          productStock={productStock}
          requestedQuantity={currentQuantity}
          cartCurrentQuantity={cartCurrentQuantity}
        />
      )}
      {cartState.isLoading && (
        <FullScreenLoading
          loading={cartState.isLoading}
          lottie={ShoppingCartLottie}
          title={t('SHOPPING_CART:UPDATING_TITLE')}
          subtitle={t('SHOPPING_CART:UPDATING_SUBTITLE')}
          height={200}
          width={400}
        />
      )}

      <FreeItemsDialog cartItem={freeCartItem} />
      <ConfirmModal
        isOpen={isFlushCartModalOpen}
        onRequestClose={toggleFlushCartModalOpen}
        onConfirm={onFlushCart}
        title={t('SHOPPING_CART:FLUSH_CART_TITLE')}
        confirmText={t('SHOPPING_CART:FLUSH_CART_CONFIRM')}
        message={t('SHOPPING_CART:FLUSH_CART_MESSAGE')}
      />
    </CartContext.Provider>
  );
};

const useCart = () => useContext(CartContext);

export { CartProvider, useCart };
