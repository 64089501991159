import { FC } from 'react';
import OrderFail from '../../components/OrderFail';
import OrderSuccess from '../../components/OrderSuccess';

interface ICheckoutResultPresentational {
  onRetryPayment: () => void;
  success: boolean;
  wireTransfer: boolean;
}

const CheckoutResultPresentational: FC<ICheckoutResultPresentational> = (props) => {
  const { onRetryPayment = () => {}, success, wireTransfer } = props;

  return (
    <div>{success ? <OrderSuccess wireTransfer={wireTransfer} /> : <OrderFail onRetryPayment={onRetryPayment} />}</div>
  );
};

export default CheckoutResultPresentational;
