import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { useQueryParam, StringParam } from 'use-query-params';
import { classNames } from '../../lib/utils';

export interface IOemBrand {
  CodiceListino: string;
  Tipo: string;
  Listino: string;
  CodiceArticolo: string;
  S: string;
  Descrizione: string;
  F: string;
  CS: string;
  Prezzo: string;
  Foto: string;
  N: string;
  V: string;
  P: string;
}

interface OemBrandFilterProps {
  oemBrandList: IOemBrand[];
}

const OemBrandFilter = ({ oemBrandList }: OemBrandFilterProps) => {
  const [selected, setSelected] = useState<null | IOemBrand>(null);
  const { t } = useTranslation('PRODUCTS');
  const [brand, setBrand] = useQueryParam('brand', StringParam);

  useEffect(() => {
    if (!brand) return setBrand(null);

    const brandOption = oemBrandList.find((option) => option.CodiceListino === brand);
    if (!brandOption) setBrand(null);
    else setSelected(brandOption);
  }, [brand]);

  return (
    <div className="my-4 flex justify-center">
      <div className="flex items-center">
        <Listbox
          value={selected}
          onChange={(selected: IOemBrand) => {
            setBrand(selected.CodiceListino);
          }}
        >
          {({ open }) => (
            <>
              <Listbox.Label className="mr-2 block text-lg font-light text-gray-500">
                {t('SELECT_MANUFACTURER')}
              </Listbox.Label>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full sm:min-w-[400px] cursor-default rounded-md bg-gray-100 py-4 pl-4 pr-10 text-left focus:outline-none sm:text-sm">
                  <span className="block truncate text-lg">{t(selected?.Listino || t('CHOOSE_MANUFACTURER'))}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {oemBrandList.map((option, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-red-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-8 pr-4 text-lg',
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {t(option.Listino)}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-red-600',
                                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
};

export default OemBrandFilter;
