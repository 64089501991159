import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const { REACT_APP_CATI_URL } = process.env;

const RecuperoCredenzialiSofinn: React.FC = () => {
  const { t } = useTranslation('LOGIN_SOFINN');

  return (
    <div className="py-[10px] px-[15px] bg-[#f5f5f5] border-t-[1px] border-[#ddd] text-[#710d28] flex-col w-full">
      <Link to={`/cati-b2b/forgot-password`} className="flex text-[14px] justify-center" rel="noreferrer">
        {t('FORGOT_PASSWORD')} <b className="ml-2">{t('CLICK_HERE')}</b>
      </Link>
      <a
        href={`${REACT_APP_CATI_URL}/richiedi-accesso-ecommerce/`}
        target="_blank"
        className="flex text-[14px] justify-center"
        rel="noreferrer"
      >
        {t('ACTIVATION_REQUEST')}
        <b className="ml-2">{t('CLICK_HERE')}</b>
      </a>
    </div>
  );
};

export default RecuperoCredenzialiSofinn;
