import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { NumberParam, useQueryParams } from 'use-query-params';
import { me, update as updateProfile } from '../../lib/fetch/auth';
import { IAddress } from '../../lib/interfaces';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import CheckoutShippingPresentational from './CheckoutShippingPresentational';
import { create as createAddress } from '../../lib/fetch/addresses';
import i18n from '../../i18n';
import { DeliveryType } from '../../lib/interfaces/ICustomer';
import { ROUTES } from '../../routes';
import { useCart } from '../../providers/CartProvider';

export enum AddressType {
  Delivery = 'delivery',
  Billing = 'billing',
}

const CheckoutShipping = () => {
  const navigate = useNavigate();
  const [{ token, user }, dispatchAuthChange] = useAuth();
  const { addresses = [] } = user!;
  const { cartState } = useCart();
  const { cart } = cartState;
  const [, dispatchAlertChange] = useAlert();
  const [addressLoading, setAddressLoading] = useState(false);
  const { t } = useTranslation('SHOPPING_CART');
  const [query, setQuery] = useQueryParams({
    deliveryAddressId: NumberParam,
    billingAddressId: NumberParam,
  });
  const { billingAddressId, deliveryAddressId } = query;

  const lang = i18n.languages[0];

  const defaultDeliveryAddress =
    (deliveryAddressId
      ? addresses.find(({ id }) => id === deliveryAddressId)
      : addresses.find(({ deliveryDefault }) => deliveryDefault)) ||
    (!!addresses.length && addresses[0]) ||
    undefined;
  const defaultBillingAddress =
    (billingAddressId
      ? addresses.find(({ id }) => id === billingAddressId)
      : addresses.find(({ billingDefault }) => billingDefault)) ||
    (!!addresses.length && addresses[0]) ||
    undefined;

  const [deliveryAddress, setDeliveryAddress] = useState<IAddress | undefined>(defaultDeliveryAddress);
  const [billingAddress, setBillingAddress] = useState<IAddress | undefined>(defaultBillingAddress);
  const [shippingMode, setShippingMode] = useState<DeliveryType>(DeliveryType.Bench);
  const [selectedShippingMode, setSelectedShippingMode] = useState<DeliveryType>(DeliveryType.Bench);

  const [noteValue, setNoteValue] = useState<string>('');

  useEffect(() => {
    // Re-select address on user update, otherwise selection is lost on address creation
    if (!addresses || !addresses.length) return;
    const billingToSelect = addresses.find(({ id }) => id === (billingAddress && billingAddress.id));
    if (billingToSelect && billingToSelect !== billingAddress) setBillingAddress(billingToSelect);
    const deliveryToSelect = addresses.find(({ id }) => id === (deliveryAddress && deliveryAddress.id));
    if (deliveryToSelect && deliveryToSelect !== deliveryAddress) setDeliveryAddress(deliveryToSelect);
  }, [addresses]);

  useEffect(() => {
    const type = user?.shipping_mode || user?.customer.shipping_mode || DeliveryType.Courier;
    setSelectedShippingMode(type === DeliveryType.Forced_bench ? DeliveryType.Bench : type);
    setShippingMode(type);
  }, [user]);

  const refetchProfile = async () => {
    const { data: updatedUser } = await me(token!, lang);
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
  };

  const onAddressCreate = async (newAddress: IAddress, type: AddressType) => {
    setAddressLoading(true);

    const { error: createAddressError, data: createdAddress } = await createAddress(token!, newAddress);

    if (createAddressError) {
      let { data: { error: { message = undefined } = {} } = {} } = createAddressError;
      setAddressLoading(false);
      return dispatchAlertChange({
        open: true,
        message,
      });
    }

    const addressesIds = (user!.addresses || []).map((address) => address.id).concat(createdAddress.data.id);

    const { error: updateProfileError } = await updateProfile(token!, {
      id: user!.id,
      addresses: addressesIds,
    });

    if (updateProfileError) {
      let { data: { error: { message = undefined } = {} } = {} } = updateProfileError;
      setAddressLoading(false);
      return dispatchAlertChange({
        open: true,
        message,
      });
    }

    await onAddressChange(
      {
        id: createdAddress.data.id,
        ...createdAddress.data.attributes,
      },
      type,
    );
    const otherType = type === AddressType.Billing ? AddressType.Delivery : AddressType.Billing;
    const isNewDefaultForOtherType =
      otherType === AddressType.Billing
        ? createdAddress.data.attributes.deliveryDefault
        : createdAddress.data.attributes.billingDefault;

    if (isNewDefaultForOtherType) {
      await onAddressChange(
        {
          id: createdAddress.data.id,
          ...createdAddress.data.attributes,
        },
        otherType,
      );
    }

    await refetchProfile();
    setAddressLoading(false);
    dispatchAlertChange({
      message: t('PROFILE:ADDRESS_CREATE_SUCCESS'),
      open: true,
      type: AlertType.Success,
    });
  };

  const onAddressChange = async (address: IAddress, type: AddressType) => {
    switch (type) {
      case AddressType.Billing:
        setBillingAddress(address);
        setQuery({
          ...query,
          billingAddressId: address.id,
        });
        break;
      case AddressType.Delivery:
        // const { error } = await updateCartShippingAddress(address.id);
        // if (error) break;
        setDeliveryAddress(address);
        setQuery({
          ...query,
          deliveryAddressId: address.id,
        });
        break;
      default:
    }
  };

  const onAddressChangeAndRefetchProfile = async (address: IAddress, type: AddressType) => {
    if (type === AddressType.Billing) return onAddressChange(address, type);

    setAddressLoading(true);
    await onAddressChange(address, type);
    await refetchProfile();
    setAddressLoading(false);
  };

  const onCheckoutProceed = () => {
    const qString = {
      deliveryAddressId: deliveryAddress!.id,
      billingAddressId: billingAddress!.id,
      deliveryType: selectedShippingMode,
      noteValue: noteValue,
    };
    navigate(`${ROUTES.CHECKOUT.PAYMENT}?${queryString.stringify(qString)}`);
  };

  return (
    <CheckoutShippingPresentational
      cart={cart}
      addresses={addresses}
      addressLoading={addressLoading}
      deliveryAddress={deliveryAddress}
      billingAddress={billingAddress}
      onAddressCreate={onAddressCreate}
      onAddressChange={onAddressChangeAndRefetchProfile}
      shippingMode={shippingMode}
      selectedShippingMode={selectedShippingMode}
      onDeliveryTypeChange={setSelectedShippingMode}
      noteValue={noteValue}
      setNoteValue={setNoteValue}
      onCheckoutProceed={onCheckoutProceed}
    />
  );
};

export default CheckoutShipping;
