import { useTranslation } from 'react-i18next';
import { IPromotion } from '../../../../lib/fetch/products';
import { InfoDetailBox } from '../../../../pages/ProductDetail/Components';
import { getPromoDescription } from '../../../../lib/utils/promotions';

interface IPromotionProps {
  promotion?: IPromotion;
}

const Promotions = (props: IPromotionProps) => {
  const { promotion } = props;
  const {
    t,
    i18n: { language },
  } = useTranslation('PRODUCTS');

  if (!promotion) return <>{t('PROMOTION_UNAVAILABLE')}</>;

  const promotionDetails = {
    title: promotion.title,
    value: getPromoDescription(promotion, language),
  };

  return (
    <div className="flex flex-col gap-4 md:flex-row">
      <div className="flex-1">
        <h3 className="text-md mb-4 font-semibold text-gray-600">{t('PROMOTION_DETAIL')}</h3>
        <div className="text-xs">{<InfoDetailBox details={[promotionDetails]} />}</div>
      </div>
    </div>
  );
};

export default Promotions;
