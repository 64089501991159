import SearchHeaderPresentational from './SearchHeaderPresentational';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../../providers/AlertProvider';
import { SORTING_OPTIONS } from '../../ProductsTable/HorizontalFilter';
import { WhereToSearch } from '../../../lib/enums';
import { ROUTES } from '../../../routes';

export interface ISearchParams {
  addToCart: boolean;
  search: string;
  where: string;
}

const SORT_DEFAULT = SORTING_OPTIONS[0].value;
const MIN_SEARCH_LENGTH = 2;

const SearchHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [query, setQuery] = useQueryParams({
    addToCart: BooleanParam,
    search: StringParam,
    brand: StringParam,
    categoryId: StringParam,
    categoryName: StringParam,
    sort: StringParam,
    where: StringParam,
  });
  const { search, brand, sort, where, categoryId, categoryName } = query || {};
  const { t } = useTranslation('PRODUCTS');
  const [, dispatchErrorAlertChange] = useAlert();

  const onSearch = (onSearchParams: ISearchParams) => {
    const { addToCart, search: _search, where } = onSearchParams;
    const search = (_search || '').trim();
    if (search.length < MIN_SEARCH_LENGTH)
      return dispatchErrorAlertChange({
        open: true,
        message: t('SEARCH_MIN_LENGHT', {
          minLength: MIN_SEARCH_LENGTH,
        }),
      });
    const { pathname } = location;
    if (pathname !== ROUTES.SEARCH.SEARCH_RESULTS) {
      const qString = {
        addToCart: addToCart ? 1 : 0,
        search,
        brand: where !== WhereToSearch.OemCode ? '' : brand,
        categoryId: where !== WhereToSearch.Categories ? '' : categoryId,
        categoryName: where !== WhereToSearch.Categories ? '' : categoryName,
        sort: SORT_DEFAULT,
        where,
      };
      navigate(`${ROUTES.SEARCH.SEARCH_RESULTS}?${queryString.stringify(qString)}`);
    } else {
      setQuery({
        addToCart,
        search,
        brand: where !== WhereToSearch.OemCode ? '' : brand,
        categoryId: where !== WhereToSearch.Categories ? '' : categoryId,
        categoryName: where !== WhereToSearch.Categories ? '' : categoryName,
        sort: sort || SORT_DEFAULT,
        where,
      });
    }
  };
  return (
    <SearchHeaderPresentational
      onSearch={onSearch}
      search={search as string}
      brand={brand as string}
      where={where as string}
    />
  );
};

export default SearchHeader;
