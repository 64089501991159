import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FullScreenLoading from '../../components/FullScreenLoading';
import { TabBarBig, ITab } from '../../components/TabBars';
import MessageAlert from '../../components/Alert/MessageAlert';
import Table, { HorizontalFilter, Pagination } from '../../components/ProductsTable';
import { IProduct, IPromotion } from '../../lib/fetch/products';
import MagGlass from '../../lotties/mag-glass.json';
import { Sidebar } from '../../components/Sidebar';
import { IFrameBannerSearchBottom } from '../../components/IFrameBanner/SearchBottom';
import IconButtonGroup from '../../components/IconButtonGroup';
import { ShoppingBagIcon, ShoppingCartIcon, ChartBarIcon, CheckIcon } from '@heroicons/react/24/solid';
import { ROUTES } from '../../routes';
import PromotionCarousel from '../../components/News/PromotionCarousel/PromotionCarousel';
import { WhereToSearch } from '../../lib/enums';
import OemBrandFilter, { IOemBrand } from './OemBrandFilter';
import { CategorySearch } from './CategorySearch';
import { ICategorySearchCount } from '../../lib/fetch/products/search';
import { StringParam, useQueryParam } from 'use-query-params';

interface ISearchResultsPresentational {
  adding: boolean;
  loading: boolean;
  onFetchCrossReferences: (productId: number) => Promise<IProduct[]>;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  products: IProduct[];
  search?: string;
  categoryId?: string;
  brands?: IOemBrand[];
  categories?: ICategorySearchCount[];
  brand?: string | null;
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
  where: string | null | undefined;
  promotions: IPromotion[] | undefined;
}

const SearchResultsPresentational: FC<ISearchResultsPresentational> = (props) => {
  const {
    loading = false,
    onFetchCrossReferences,
    onPageChange = () => {},
    onPageSizeChange = () => {},
    products = [],
    brands = [],
    categories = [],
    search,
    categoryId,
    brand,
    page = 1,
    pageCount = 1,
    pageSize = 0,
    total = 0,
    where,
    promotions,
  } = props;

  const [categoryName] = useQueryParam('categoryName', StringParam);
  const { t } = useTranslation('PRODUCTS');
  const { t: tNav } = useTranslation('NAVIGATION');

  const tabs: ITab[] = [
    {
      name: t('ALL'),
      count: total,
      current: true,
    },
    /*
    {
      name: t('WITHIN_TODAY'),
      count: 0,
      current: false,
    },
    {
      name: t('WITHIN_NEXT_DAY'),
      count: 0,
      current: false,
    },
    {
      name: t('WITHIN_TWO_DAYS'),
      count: 0,
      current: false,
    },
    */
  ];

  const navigationItems = [
    {
      icon: ShoppingBagIcon,
      label: tNav('RETURNS'),
      link: ROUTES.PROFILE.RETURNS,
    },
    {
      icon: ShoppingCartIcon,
      label: tNav('ORDERS'),
      link: ROUTES.ORDERS.LIST,
    },
    {
      icon: ChartBarIcon,
      label: tNav('STATISTICS'),
      link: ROUTES.PROFILE.COMMERCIAL_STATS,
    },
    {
      icon: CheckIcon,
      label: tNav('DELIVERY_NOTES'),
      link: ROUTES.PROFILE.DOCUMENTS,
    },
  ];

  return (
    <div>
      <div className="w-full justify-center mt-8 hidden lg:flex">
        <IconButtonGroup items={navigationItems} />
      </div>
      {promotions && promotions.length > 0 && (
        <div className="overflow-hidden">
          <PromotionCarousel promotions={promotions} />
        </div>
      )}
      <div className="mx-auto w-full flex-grow overflow-x-hidden">
        <div className="flex flex-col lg:flex-row min-h-screen bg-white lg:px-4">
          <div className="order-last lg:order-first w-full lg:w-auto">
            <Sidebar />
          </div>
          <div className="order-first lg:order-last flex-1 py-6 px-4 lg:pr-0">
            {!search && !categoryId && <>{categories.length > 0 && <CategorySearch categories={categories} />}</>}
            {search && !loading && !products?.length && !brands?.length && (
              <div className="mx-auto max-w-xl text-center">
                <MessageAlert text={where === 'code' ? t('NO__PARTIAL_CODE_RESULT') : t('NO_RESULT')} />
              </div>
            )}

            <FullScreenLoading
              loading={loading}
              lottie={MagGlass}
              title={t('SEARCH_IN_PROGRESS_TITLE')}
              subtitle={t('SEARCH_IN_PROGRESS_SUBTITLE')}
              height={200}
              width={400}
            />

            {where !== WhereToSearch.OemCode && products?.length ? (
              <div>
                {categoryName && (
                  <div className="font-bold text-2xl text-[#1A202C] mb-4">
                    {`${t('CATEGORY_SEARCH')}: ${categoryName}`}
                  </div>
                )}
                <TabBarBig tabs={tabs} />
                {!categoryName && <HorizontalFilter />}
                <Table products={products} onFetchCrossReferences={onFetchCrossReferences} />
                <Pagination
                  currentItems={products}
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                  page={page}
                  pageCount={pageCount}
                  pageSize={pageSize}
                  total={total}
                />
              </div>
            ) : null}
            {where === WhereToSearch.OemCode && brands?.length ? (
              <>
                <div>
                  <OemBrandFilter oemBrandList={brands as any as IOemBrand[]} />
                </div>
                {products && products?.length === 0 && (
                  <div className="mx-auto max-w-xl text-center">
                    <MessageAlert text={t('NO_RESULT')} />
                  </div>
                )}
              </>
            ) : null}
            {where === WhereToSearch.OemCode && brand && brands?.length && products && products?.length ? (
              <div>
                <TabBarBig tabs={tabs} />
                <HorizontalFilter />
                <Table products={products} onFetchCrossReferences={onFetchCrossReferences} />
                <Pagination
                  currentItems={products}
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                  page={page}
                  pageCount={pageCount}
                  pageSize={pageSize}
                  total={total}
                />
              </div>
            ) : null}
            <IFrameBannerSearchBottom />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultsPresentational;
