import axios from 'axios';
import { ICart } from '.';

const { REACT_APP_BACKEND_URL } = process.env;
const myCartUrl = REACT_APP_BACKEND_URL! + '/my-cart';
const myCart = async (token: string, lang?: string) => {
  const response = await axios.get<ICart>(myCartUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      locale: lang,
    },
  });

  let error;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default myCart;
