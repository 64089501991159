import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { useAuth } from '../../../providers/AuthProvider';

const ReturnInfo = () => {
  const { t } = useTranslation('PROFILE');
  const [{ user }] = useAuth();
  return (
    <div className="bg-white lg:min-w-0 lg:flex-1">
      <div className="h-full py-6 px-4 lg:pr-0">
        <div className="mb-6 rounded-xl border border-gray-300 px-6 py-6">
          <h3 className="mb-6 text-2xl font-bold text-gray-800">{t('RETURN_INFO')}</h3>

          <div className="mt-6 bg-gray-50 py-4 px-6 text-center">
            {user?.returnsEnabled ? (
              <div className="flex flex-col items-center">
                {' '}
                <p
                  className="text-normal text-gray-600"
                  dangerouslySetInnerHTML={{ __html: t('RETURN_INFO_DESCRIPTION') }}
                />
                <a
                  href="mailto:dashboard@cati.it"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-auto mt-6 inline-block"
                >
                  <PrimaryButton text={t('RETURNS_SECTION')} />
                </a>
                <button
                  type="button"
                  className="mx-auto mt-6 inline-block mb-2 rounded-md border border-red-600 bg-white py-2 px-4 text-sm font-normal text-red-600 shadow-sm hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  {t('RETURNS_LIST')}
                </button>
                <p
                  className="text-normal text-gray-600 underline"
                  dangerouslySetInnerHTML={{ __html: t('RETURN_CONDITIONS') }}
                />
              </div>
            ) : (
              <div>
                {' '}
                <p className="text-normal text-gray-600" dangerouslySetInnerHTML={{ __html: t('RETURNS_DISABLED') }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnInfo;
