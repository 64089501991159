import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { ShoppingCartIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import MessageAlert from '../../Alert/MessageAlert';
import SingleProduct from './SingleProduct';
import Subtotal from '../../Subtotal';
import { useCart } from '../../../providers/CartProvider';

const ShoppingCart = () => {
  const { t } = useTranslation('PRODUCTS');
  const navigate = useNavigate();
  const {
    cartState: { cart, totalQuantity },
    dispatchOverlayChange,
    isOverlayOpen,
    flushCartWithConfirm,
    isOverlayEnabled,
  } = useCart();
  let { cart_items: cartItems, shipping: cartShipping, messages } = cart || {};
  const { shipping_address: shippingAddress = null } = cart || {};
  cartItems = cartItems || [];
  cartShipping = cartShipping || 0;

  return (
    <>
      <div
        className="group -m-2 flex cursor-pointer items-center p-2"
        onClick={() => dispatchOverlayChange({ open: true })}
      >
        <ShoppingCartIcon
          className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
        <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{totalQuantity}</span>
      </div>

      <Transition.Root show={isOverlayOpen && isOverlayEnabled} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-hidden"
          onClose={(open) => dispatchOverlayChange({ open })}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-gray-50 shadow-xl">
                    <div className="px-4 sm:px-6 py-6">
                      <div className="flex items-start justify-end">
                        <div className="ml-3 mb-3 flex h-7 items-center sm:hidden">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={() => dispatchOverlayChange({ open: false })}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative flex-1 flex flex-col justify-between">
                      {totalQuantity ? (
                        <>
                          <div className="flex-1 overflow-y-auto px-4">
                            {cartItems.map((cartItem, index) => (
                              <SingleProduct key={cartItem.id} cartItem={cartItem} />
                            ))}
                          </div>

                          {!!messages?.length &&
                            messages.map((message) => (
                              <div className="mb-4 text-center">
                                <MessageAlert text={t(message)} />
                              </div>
                            ))}

                          <div className="mt-6 sticky bottom-0 bg-gray-50">
                            <Subtotal cartItems={cartItems} shipping={cartShipping} shippingAddress={shippingAddress} />

                            <div className="px-4 py-4 flex">
                              <button
                                type="button"
                                className="flex w-2/6 items-center justify-center mr-2 rounded-md border border-red-600 bg-white py-2 px-4 text-sm font-normal text-red-600 shadow-sm hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                disabled={!cartItems || cartItems.length === 0}
                                onClick={() => flushCartWithConfirm()}
                              >
                                {t('FLUSH_CART')}
                              </button>
                              <button
                                type="button"
                                className="flex w-4/6 items-center justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                onClick={() => {
                                  dispatchOverlayChange({ open: false });
                                  setTimeout(() => {
                                    navigate('/shopping-cart');
                                    // Must be greater than leave duration, or page scrolling will get stuck!
                                  }, 1000);
                                }}
                              >
                                <ShoppingCartIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                                {t('GO_TO_CART')}
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="mb-4 px-4 text-center">
                          <MessageAlert text={t('ADD_PRODUCT_MESSAGE')} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ShoppingCart;
