import SofinnResetTemporaryPasswordFeedbackMessage from './SofinnResetTemporaryPasswordFeedbackMessage';

interface Props {
  onRedirect: (e: { isError?: boolean; isSuccess?: boolean }) => void;
}

const SofinnResetTemporaryPasswordFeedbackMessagePresentational: React.FC<Props> = (props) => {
  const { onRedirect } = props;
  return (
    <div className="h-screen flex flex-col justify-between">
      <div className="flex w-full flex-col justify-center align-middle">
        <div className="flex w-full px-[70px] pt-[30px]">
          <img className="flex w-[110px] h-[110px]" src="/images/sofinn/logo-cati-sofinn.png" alt="logo" />
        </div>
        <SofinnResetTemporaryPasswordFeedbackMessage onRedirect={onRedirect} />
      </div>
    </div>
  );
};

export default SofinnResetTemporaryPasswordFeedbackMessagePresentational;
