import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { IErrorImport } from './utils';
import MessageAlert from '../Alert/MessageAlert';

interface Props {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  searchError: IErrorImport[];
  productsNotAvailable: IErrorImport[];
  productsNotMinQuantity: IErrorImport[];
  addedProducts: string[];
}

const ImportProductCodesReportModal: React.FC<Props> = (props) => {
  const { open, onClose, loading, searchError, addedProducts, productsNotAvailable, productsNotMinQuantity } = props;
  const { t } = useTranslation('IMPORT');
  const initialRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" initialFocus={initialRef} onClose={() => {}}>
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle">
              <div>
                <div className="flex justify-between items-center">
                  <Dialog.Title as="h3" className="text-xl font-bold leading-6 text-gray-600 flex-grow">
                    {t('REPORT_IMPORTATION')}
                  </Dialog.Title>
                  <button
                    type="button"
                    onClick={() => onClose()}
                    className="inline-flex items-center rounded-full border border-transparent p-0.5 text-gray-600 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  >
                    <XMarkIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
                  </button>
                  <button className="display-none" ref={initialRef} />
                </div>

                <div className="flex flex-col">
                  <div>
                    <span>
                      {t('IMPORTED_PRODUCTS')}: {addedProducts.length}
                    </span>
                  </div>
                  {productsNotAvailable.length > 0 && (
                    <div>
                      <span>
                        {t('PRODUCTS_NOT_AVAILABLE')}: {productsNotAvailable.length}
                      </span>
                      <div className="mb-4 pl-4 text-left">
                        <MessageAlert
                          text={productsNotAvailable.map((error) => (
                            <ol className="flex list-disc">
                              <li
                                key={error.code}
                              >{`${error.code} (${t('AVAILABILITY')}: ${error.details?.availableQuantity})`}</li>
                            </ol>
                          ))}
                        />
                      </div>
                    </div>
                  )}
                  {searchError.length > 0 && (
                    <div>
                      <span>
                        {t('PRODUCTS_NOT_FOUND')}: {searchError.length}
                      </span>
                      <div className="mb-4 pl-4 text-left">
                        <MessageAlert
                          text={searchError.map((error) => (
                            <ol className="flex list-disc">
                              <li key={error.code}>{error.code}</li>
                            </ol>
                          ))}
                        />
                      </div>
                    </div>
                  )}
                  {productsNotMinQuantity.length > 0 && (
                    <div>
                      <span>
                        {t('PRODUCTS_NOT_MIN_QUANTITY')}: {productsNotMinQuantity.length}
                      </span>
                      <div className="mb-4 pl-4 text-left">
                        <MessageAlert
                          text={productsNotMinQuantity.map((error) => (
                            <ol className="flex list-disc">
                              <li key={error.code}>{error.code}</li>
                            </ol>
                          ))}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="mt-4 flex justify-end">
                  <button
                    type="submit"
                    className="flex items-center justify-center rounded-full border border-transparent bg-red-600 py-2 px-3 text-xs font-normal text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 lg:rounded-md lg:px-4 disabled:bg-red-400"
                    onClick={onClose}
                    disabled={loading}
                  >
                    <span className="hidden lg:inline-block">{t('CLOSE')}</span>
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ImportProductCodesReportModal;
