import React, { useState, useRef, useEffect, useMemo, Fragment } from 'react';
import {
  ShoppingBagIcon,
  ShoppingCartIcon,
  ChartBarIcon,
  CheckIcon,
  EllipsisVerticalIcon,
  NewspaperIcon,
  ChatBubbleLeftIcon,
  FolderIcon,
  DocumentIcon,
  CurrencyEuroIcon,
  PuzzlePieceIcon,
  GiftTopIcon,
} from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../routes';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import { isOrdersViewEnabled } from '../../../lib/utils/userPermissions';
import { Transition } from '@headlessui/react';

const AllFunctionsButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const panelRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('MENU');
  const { t: tNav } = useTranslation('NAVIGATION');
  const [{ user }] = useAuth();
  const areOrdersEnabled = useMemo(() => isOrdersViewEnabled(user!), [user]);

  const allFunctionsItems = [
    {
      icon: CurrencyEuroIcon,
      label: tNav('PROMOTIONS'),
      link: ROUTES.PROMOTIONS.LIST,
    },
    {
      icon: ShoppingCartIcon,
      label: tNav('ORDERS'),
      link: ROUTES.ORDERS.LIST,
      disabled: !areOrdersEnabled,
    },
    {
      icon: GiftTopIcon,
      label: tNav('DELIVERY_NOTES'),
      link: ROUTES.PROFILE.DOCUMENTS,
    },
    {
      icon: DocumentIcon,
      label: tNav('DOCUMENTS'),
      link: '/search-results',
      disabled: true,
    },
    {
      icon: NewspaperIcon,
      label: tNav('NEWS'),
      link: ROUTES.NEWS.LIST,
    },
    {
      icon: ChatBubbleLeftIcon,
      label: tNav('COMMUNICATIONS'),
      link: '/search-results',
      disabled: true,
    },
    {
      icon: ShoppingBagIcon,
      label: tNav('RETURNS'),
      link: ROUTES.PROFILE.RETURNS,
    },
    {
      icon: FolderIcon,
      label: tNav('CATALOGUES'),
      link: '/search-results',
      disabled: true,
    },
    /*
    {
      icon: PuzzlePieceIcon,
      label: tNav('SERVICES'),
      link: '/search-results',
      disabled: true,
    },
    */
    {
      icon: ChartBarIcon,
      label: tNav('STATISTICS'),
      link: ROUTES.PROFILE.COMMERCIAL_STATS,
    },
  ];

  const togglePanel = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block">
      <button
        ref={buttonRef}
        className="flex w-full items-center justify-center rounded-full border border-transparent bg-blue-500 py-2 px-3 text-xs font-normal text-white shadow-sm hover:bg-blue-600 focus:outline-none lg:w-auto lg:rounded-md"
        onClick={togglePanel}
      >
        <span className="hidden sm:inline-block">{t('ALL_FUNCTIONS')}</span>
        <EllipsisVerticalIcon className="h-4 w-4" aria-hidden="true" />
      </button>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-300"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-300"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          ref={panelRef}
          className="absolute top-full right-0 bg-white border border-gray-300 rounded-lg shadow-lg p-3 z-50 w-72 grid grid-cols-3 gap-4"
        >
          {allFunctionsItems.map((item, index) => (
            <Link key={index} to={item.link} className="text-center w-16">
              <div
                className={`${item?.disabled ? 'bg-red-800 cursor-not-allowed' : 'bg-red-500 hover:bg-red-600'} rounded-full p-3 text-white transition-colors w-12 h-12 flex items-center justify-center mx-auto`}
                onClick={(e) => {
                  if (item?.disabled) {
                    e.preventDefault();
                  }
                }}
              >
                <item.icon className="w-6 h-6" />
              </div>
              <span className="text-xs mt-1 block md:block hidden">{item.label}</span>
            </Link>
          ))}
        </div>
      </Transition>
    </div>
  );
};

export default AllFunctionsButton;
