import axios, { AxiosResponse } from 'axios';
import { ICartItemProduct } from '.';

const { REACT_APP_CART_ITEMS_URL } = process.env;

export interface ICartItemCreate {
  quantity: number;
  price: number;
  discount: number;
  taxes: number;
  shipping: number;
  product: number | ICartItemProduct;
  excerpt?: boolean;
  selectedForOrder?: boolean;
  original_price: number;
}

export interface ICartItemCreateResult {
  id: number;
  attributes: {
    quantity: number;
    price: number;
    discount: number;
    taxes: number;
    shipping: number;
    product: number;
  };
}

const create = async (token: string, cartItem: ICartItemCreate) => {
  const response = await axios.post<{ data: ICartItemCreateResult }>(
    REACT_APP_CART_ITEMS_URL!,
    { data: cartItem },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default create;
