import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import i18n from '../../i18n';
import { Language } from '../../lib/enums';
import { NewsPost } from '../../lib/models/NewsPost';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { getMediaUrl } from '../../lib/utils';

export interface INewsDetailProps {
  news: NewsPost | null;
  open: boolean;
  onClose: () => void;
}

const NewsDetail = (props: INewsDetailProps) => {
  const { news, open, onClose } = props;

  const initialRef = useRef(null);
  const lang = i18n.languages[0];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={initialRef}
        onClose={() => onClose()}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle">
              <div>
                <div className="flex justify-between items-center">
                  <p className="mb-1 text-center text-sm font-bold text-gray-400 flex-grow">
                    {news?.attributes.date
                      ? moment(news.attributes.date)
                          .format(lang === Language.IT ? 'dddd D MMMM YYYY' : 'dddd Do MMMM YYYY')
                          .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase())
                      : ''}
                  </p>
                  <button
                    type="button"
                    onClick={() => onClose()}
                    className="inline-flex items-center rounded-full border border-transparent p-0.5 text-gray-600 hover:text-gray-700 focus:outline-none"
                  >
                    <XMarkIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
                  </button>
                </div>

                <img
                  src={getMediaUrl(news?.attributes.image?.data?.attributes.url)}
                  alt={news?.attributes.title || ''}
                  className="mr-auto ml-auto mt-4 mb-7 h-1/5"
                />
                <Dialog.Title as="h3" className="text-center text-xl font-bold leading-6 text-gray-600">
                  {news?.attributes.title || ''}
                </Dialog.Title>

                <div className="prose mt-6 py-4 px-6 text-base text-gray-600">
                  <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                    {news?.attributes.content || ''}
                  </Markdown>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewsDetail;
