import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import { ELEMENT_TYPE, shouldShowElementInRoute } from './LayoutUtils';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();

  const shouldShowHeader = shouldShowElementInRoute(location.pathname, ELEMENT_TYPE.HEADER);
  const shouldShowFooter = shouldShowElementInRoute(location.pathname, ELEMENT_TYPE.FOOTER);

  const wrapperClassName = shouldShowFooter || shouldShowHeader ? 'min-h-screen' : '';

  return (
    <div className={wrapperClassName}>
      {shouldShowHeader && <Header />}
      <main>{children}</main>
      {shouldShowFooter && <Footer />}
    </div>
  );
};

export default Layout;
