import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { To } from 'react-router-dom';
import { FUNDING as PayPalFunding, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { BackButton, PrimaryButtonFull } from '../../components/Buttons';
import Subtotal from '../../components/Subtotal';
import RadioGroupPayment from './Components/RadioGroupPayment';
import { PaymentMethod } from '../../lib/enums';
import { IAddress } from '../../lib/interfaces';
import { ICart } from '../../lib/fetch/carts';
import { IOrderItem } from '../../lib/fetch/orderItems';
import { IOrder } from '../../lib/fetch/orders';
import { IWireTransferDataAttributes } from '../../lib/fetch/wireTransfer';
import { orderUtils, payPalUtils } from '../../lib/utils';
import { ALLOWED_PAYMENT_METHODS } from '../../lib/constants';
import { calculateCartTotals } from '../../lib/utils/cart';
interface ICheckoutPaymentPresentational {
  addresses: IAddress[];
  cart?: ICart | null;
  deliveryAddressId: number | null | undefined;
  loading: boolean;
  onCheckoutProceed: (
    paymentMethod: PaymentMethod,
    paymentData?: { wireTransferData?: IWireTransferDataAttributes },
  ) => Promise<void | { orderId?: number }>;
  onPayPalError: () => void;
  onPayPalSuccess: (orderId?: string) => void;
  order?: IOrder;
  wireTransferData: IWireTransferDataAttributes | undefined;
}

const CheckoutPaymentPresentational: FC<ICheckoutPaymentPresentational> = (props) => {
  const {
    addresses = [],
    cart,
    deliveryAddressId,
    loading = false,
    onCheckoutProceed = async (_: PaymentMethod) => {},
    onPayPalError = () => {},
    onPayPalSuccess = () => {},
    order,
    wireTransferData,
  } = props;

  let { cart_items: cartItems, shipping: cartShipping } = cart || {};
  cartItems = cartItems || [];
  const selectedForOrderCartItems = cartItems.filter((cartItem) => cartItem.selectedForOrder);
  cartShipping = cartShipping || 0;

  let { order_items, shipping: orderShipping } = (order && order.attributes) || {};
  let orderItems = ((order_items && order_items.data) || []).map(({ id, attributes }) => ({
    id,
    ...attributes,
  })) as IOrderItem[];
  orderShipping = orderShipping || 0;

  const shippingAddress = order ? orderUtils.getOrderShippingAddress(order) : cart?.shipping_address;

  const formMethods = useForm<{ paymentMethod: PaymentMethod }>({
    defaultValues: { paymentMethod: ALLOWED_PAYMENT_METHODS[0] },
  });
  const { handleSubmit, setValue: setFormValue, watch } = formMethods;
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('SHOPPING_CART');
  const onSubmit = handleSubmit((formData) => {
    onCheckoutProceed(formData.paymentMethod, { wireTransferData });
  });
  const isPayPalSelected = watch('paymentMethod') === PaymentMethod.Paypal;
  const [{ isPending: isPayPalPending, isRejected: isPayPalRejected, isResolved: isPayPalResolved }] =
    usePayPalScriptReducer();
  const [totalAmount, setTotalAmount] = useState('');

  useEffect(() => {
    if (!isPayPalSelected || !isPayPalRejected || isPayPalResolved) return;
    dispatchAlertChange({
      open: true,
      message: t('PAYPAL_UNAVAILABLE'),
    });
    setFormValue('paymentMethod', PaymentMethod.Card);
  }, [isPayPalSelected]);

  return (
    <div className="flex flex-col gap-6 lg:flex-row">
      <div className="flex h-full min-w-0 flex-1 flex-col px-4 py-10 lg:pl-6 lg:pr-0">
        <div className="mb-6">
          <BackButton text={t('COMMON:BACK')} to={-1 as To} />
        </div>

        <div className="mb-6 border-b pb-4 text-sm font-medium" aria-current="step">
          <span className="text-lg font-semibold text-gray-900">{t('CHOOSE_PAYMENT_TITLE')}</span>
        </div>
        <FormProvider {...formMethods}>
          <RadioGroupPayment wireTransferData={wireTransferData} />
        </FormProvider>
      </div>

      <aside className="mx-auto w-full overflow-y-auto bg-gray-50 py-10 lg:w-2/6">
        <div className="px-4">
          <h3 className="mb-4 text-lg font-bold text-gray-900">{t('ORDER_SUMMARY')}</h3>
        </div>
        <Subtotal
          {...(orderItems.length ? { orderItems } : { cartItems: selectedForOrderCartItems })}
          setTotalAmount={(total) => setTotalAmount((total || 0).toFixed(2))}
          shipping={order ? orderShipping : cartShipping}
          shippingAddress={shippingAddress}
        />

        {!isPayPalSelected && (
          <div className="mt-10 px-4 uppercase">
            <PrimaryButtonFull
              text={t('PROCEED_BTN')}
              loading={loading}
              onClick={(e) => {
                const { total } = calculateCartTotals(selectedForOrderCartItems, orderShipping);
                if ((selectedForOrderCartItems?.length || orderItems?.length) && total > 0) return onSubmit();
                e.preventDefault();
                dispatchAlertChange({
                  open: true,
                  message: t('EMPTY_CART_ERROR'),
                });
              }}
            />
          </div>
        )}
        {isPayPalSelected &&
          (isPayPalPending ? (
            <div className="mt-10 px-4 uppercase">
              <PrimaryButtonFull text="" loading onClick={(e) => e.preventDefault()} />
            </div>
          ) : (
            <div className="relative z-0 mt-10 px-4">
              <PayPalButtons
                forceReRender={[totalAmount]}
                fundingSource={PayPalFunding.PAYPAL}
                disabled={loading}
                createOrder={async (data, actions) => {
                  const { orderId } =
                    ((await onCheckoutProceed(PaymentMethod.Paypal)) as {
                      orderId: number;
                    }) || {};
                  if (!orderId) throw new Error('Order creation failed.'); // Throw an error to prevent PayPal order creation. The error is caught by the onError prop.
                  return actions.order.create({
                    ...payPalUtils.getPayPalOrder({
                      addresses,
                      cart,
                      orderId: `${orderId}`,
                      deliveryAddressId: deliveryAddressId as number,
                      description: t('ORDER_DESCRIPTION', { orderId }),
                      order,
                      totalAmount,
                    }),
                  });
                }}
                onApprove={async (data, actions) => {
                  try {
                    const paymentDetails = await actions.order?.capture();
                    if (!paymentDetails) return onPayPalError();
                    const { purchase_units } = paymentDetails;
                    const [purchaseUnit] = purchase_units || [];
                    if (!purchaseUnit) return onPayPalError();
                    const { custom_id } = purchaseUnit;
                    // If custom_id is undefined, the order status will not be set to "Processing", but the payment may still be successful.
                    await onPayPalSuccess(custom_id);
                  } catch (e) {
                    await onPayPalError();
                  }
                }}
                onClick={(data, actions) => {
                  if (!cartItems?.length && !orderItems?.length) {
                    dispatchAlertChange({
                      open: true,
                      message: t('EMPTY_CART_ERROR'),
                    });
                    return actions.reject(); // Do nothing on click.
                  } else if (!+totalAmount) {
                    dispatchAlertChange({
                      open: true,
                      type: AlertType.Error,
                      message: t('ZERO_TOTAL_ERROR'),
                    });
                    return actions.reject(); // Do nothing on click.
                  } else return actions.resolve();
                }}
                onCancel={onPayPalError}
                onError={onPayPalError}
                onShippingChange={(data, actions) => {
                  try {
                    const { amount } = data as any; // Not in types!
                    const { value = '' } = amount || {};
                    // Do nothing if value is undefined/falsy. Reject if different from expected.
                    if (value && +value !== +totalAmount) return actions.reject();
                    const { shipping_address } = data;
                    if (!shipping_address) return actions.reject();
                    return payPalUtils.validatePayPalShippingAddress({
                      addresses,
                      deliveryAddressId: deliveryAddressId as number,
                      order,
                      paypalShippingAddress: shipping_address,
                    })
                      ? actions.resolve()
                      : actions.reject();
                  } catch (e) {
                    return actions.reject();
                  }
                }}
              />
            </div>
          ))}
      </aside>
    </div>
  );
};

export default CheckoutPaymentPresentational;
