import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputButtonPresentational from '../../components/Form/InputButtonPresentational';
import FullScreenLoading from '../../components/FullScreenLoading';
import { OutlineButton } from '../../components/Buttons/OutlineButton';
import {
  ArchiveBoxIcon,
  BookmarkIcon,
  PlusIcon,
  QueueListIcon,
  ShoppingCartIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { InputPresentational } from '../../components/Form';
import { Controller, useFormContext, FieldValues } from 'react-hook-form';
import { QuoteStatus } from '../../lib/enums';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import ProductsListTable from '../../components/ProductsTable/ProductsListTable';
import { BackButton } from '../../components/Buttons';
import { Summary } from '../../components/ProductsList/Summary';
import { IQuoteItem } from '../../lib/fetch/quotes';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { useCart } from '../../providers/CartProvider';
import { IProduct } from '../../lib/fetch/products';
import { IProductQuantity } from '../../components/ProductsTable';

interface IProductsListDetailPresentational {
  loading?: boolean;
  loadingSearch?: boolean;
  onSubmit: (data: FieldValues) => void;
  editQuote: (data: FieldValues) => void;
  deleteQuote: () => void;
  isCreatePage: boolean;
  onAddToList: (search: string) => void;
  loadingSave: boolean;
  toggleArchiveModal: () => void;
  toggleRestoreFromArchiveModal: () => void;
  toggleAddToCartModalOpen: () => void;
  toggleDeleteModalOpen: () => void;
  deleteModalOpen: boolean;
  archiveModalOpen: boolean;
  addToCartModalOpen: boolean;
  restoreListFromArchiveModalOpen: boolean;
}

const ProductsListDetailPresentational: FC<IProductsListDetailPresentational> = (props) => {
  const {
    loading = false,
    loadingSearch = false,
    onSubmit,
    editQuote,
    deleteQuote,
    isCreatePage,
    onAddToList,
    loadingSave,
    toggleAddToCartModalOpen,
    toggleArchiveModal,
    toggleDeleteModalOpen,
    toggleRestoreFromArchiveModal,
    deleteModalOpen,
    archiveModalOpen,
    addToCartModalOpen,
    restoreListFromArchiveModalOpen,
  } = props;
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = useFormContext();

  const status = watch('status');
  const isArchived = status === QuoteStatus.Archived;
  const isCompleted = status === QuoteStatus.Completed;
  const items: IQuoteItem[] = watch('items');
  const products: IProduct[] = items.map((item) => item.product.data);
  const productsQuantities: IProductQuantity[] = items.map((item) => ({
    id: item.product.data.id,
    product: item.product.data,
    quantity: item.quantity,
  }));
  const [, dispatchAlertChange] = useAlert();
  const [isAddToCartDisabled, setIsAddToCartDisabled] = useState(false);
  useEffect(() => {
    setIsAddToCartDisabled(isDirty);
  }, [isDirty]);
  const { onAddAll } = useCart();

  return (
    <div>
      <FullScreenLoading loading={loading} title={t('COMMON:LOADING')} subtitle={t('COMMON:LOADING_SUBTITLE')} />

      <div className="my-4 mx-4">
        <BackButton text={t('COMMON:BACK')} to="/products-list" disabled={loading} />
      </div>

      <div className="p-4 bg-gray-100 rounded-lg m-4 flex flex-col gap-4">
        <div className="flex flex-row justify-between">
          <div className="flex items-center gap-2">
            <QueueListIcon className="mh-6 w-6 text-gray-400" aria-hidden="true" />
            <h2 className="text-2xl font-bold text-gray-800">{t('QUOTE:TITLE_DETAILS')}</h2>
          </div>

          <div>
            {(() => {
              switch (status) {
                case QuoteStatus.Completed:
                  return (
                    <span className=" text-green-500 bg-green-100 px-3 py-1 rounded-full">{t('QUOTE:COMPLETED')}</span>
                  );

                case QuoteStatus.Pending:
                  return (
                    <span className=" text-yellow-600 bg-yellow-100 px-3 py-1 rounded-full">{t('QUOTE:PENDING')}</span>
                  );

                case QuoteStatus.Archived:
                  return (
                    <span className=" text-gray-700 bg-white px-3 py-1 rounded-full"> {t('QUOTE:ARCHIVED')} </span>
                  );
                default:
                  return <span className=" text-gray-600 bg-gray-100 px-3 py-1 rounded-full"> - </span>;
              }
            })()}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <h2 className="text-sm font-medium text-gray-500">{t('QUOTE:TITLE_SUGGESTION')}</h2>
        </div>
        <div className="flex flex-row gap-4">
          <div className="w-4/12">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <InputPresentational
                  label={`${t('QUOTE:TITLE_QUOTE')} *`}
                  id="name"
                  {...field}
                  disabled={isCompleted}
                  required
                />
              )}
            />
          </div>
          <div className="w-8/12">
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <InputPresentational
                  label={t('QUOTE:DESCRIPTION_QUOTE')}
                  id="description"
                  {...field}
                  disabled={isCompleted}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="p-4 mb-12">
        <div className="flex flex-col gap-6 lg:flex-row">
          <div className="flex h-full min-w-0 flex-1 flex-col">
            {!isCompleted && (
              <InputButtonPresentational
                onSubmit={(search) => onAddToList(search)}
                placeholder={t('QUOTE:SEARCH_PRODUCT')}
                textButton={t('QUOTE:ADD_TO_LIST')}
                type="text"
                loading={loadingSearch}
                icon={<PlusIcon className="h-5 w-5" aria-hidden="true" />}
              />
            )}
            <div className="mt-6 border-t py-6">
              <ProductsListTable isCompleted={isCompleted} items={items || []} />
            </div>
          </div>

          <aside className="mx-auto w-full overflow-y-auto  lg:w-2/6 flex gap-8 flex-col">
            <div className="bg-gray-50  rounded-lg  py-6">
              <div className="px-4">
                <h3 className="mb-4 text-lg font-bold text-gray-900">{t('QUOTE:SUMMARY')}</h3>
              </div>

              <Summary />
              {!isCompleted && !isCreatePage && (
                <div className="flex mt-10 px-4 gap-4">
                  <OutlineButton
                    text={t('QUOTE:ADD_TO_CART')}
                    icon={<ShoppingCartIcon className="w-6 h-6" />}
                    onClick={toggleAddToCartModalOpen}
                    disabled={items.length === 0 || isAddToCartDisabled || isDirty}
                  />
                  {/* <OutlineButton text={t('QUOTE:ORDER_NOW')} icon={<ShoppingBagIcon className="w-6 h-6" />} /> */}
                </div>
              )}
            </div>

            {!isCompleted && !isCreatePage && (
              <div className=" flex flex-col gap-4">
                <div className="flex gap-4">
                  <OutlineButton
                    text={t('QUOTE:DELETE_LIST')}
                    color="red"
                    icon={<TrashIcon className="w-6 h-6" />}
                    onClick={toggleDeleteModalOpen}
                    disabled={isDirty}
                  />

                  {!isArchived ? (
                    <OutlineButton
                      text={t('QUOTE:ARCHIVE_LIST')}
                      color="yellow"
                      icon={<ArchiveBoxIcon className="w-6 h-6" />}
                      onClick={toggleArchiveModal}
                      disabled={isDirty}
                    />
                  ) : (
                    <OutlineButton
                      text={t('QUOTE:REMOVE_FROM_ARCHIVE')}
                      color="yellow"
                      icon={<ArchiveBoxIcon className="w-6 h-6" />}
                      onClick={toggleRestoreFromArchiveModal}
                      disabled={isDirty}
                    />
                  )}
                </div>
                <OutlineButton
                  onClick={handleSubmit(onSubmit)}
                  text={t('QUOTE:SAVE_CHANGES')}
                  color="green"
                  disabled={!isDirty || loadingSave}
                  loading={loadingSave}
                  icon={<BookmarkIcon className="w-6 h-6" />}
                />
              </div>
            )}
            {isCreatePage && (
              <OutlineButton
                onClick={handleSubmit(onSubmit)}
                text={t('QUOTE:SAVE_NEW_LIST')}
                color="green"
                disabled={!isDirty || loadingSave}
                loading={loadingSave}
                icon={<BookmarkIcon className="w-6 h-6" />}
              />
            )}
          </aside>
        </div>
      </div>

      <ConfirmModal
        isOpen={deleteModalOpen}
        onRequestClose={toggleDeleteModalOpen}
        onConfirm={() => deleteQuote()}
        title={t('QUOTE:DELETE_LIST')}
        message={t('QUOTE:DELETE_LIST_DESCRIPTION')}
        confirmText={t('QUOTE:DELETE_BTN')}
      />
      <ConfirmModal
        isOpen={archiveModalOpen}
        onRequestClose={toggleArchiveModal}
        onConfirm={() => editQuote({ status: QuoteStatus.Archived })}
        title={t('QUOTE:ARCHIVE_LIST')}
        message={t('QUOTE:ARCHIVE_LIST_DESCRIPTION')}
        confirmText={t('QUOTE:ARCHIVE_BTN')}
      />
      <ConfirmModal
        isOpen={restoreListFromArchiveModalOpen}
        onRequestClose={toggleRestoreFromArchiveModal}
        onConfirm={() => editQuote({ status: QuoteStatus.Pending })}
        title={t('QUOTE:RESTORE_LIST')}
        message={t('QUOTE:REMOVE_FROM_ARCHIVE_DESCRIPTION')}
        confirmText={t('QUOTE:RESTORE_BTN')}
      />
      {/** TODO: sostituire con la modale per avvisare(card avviso giacenza) il cliente sui prodotti aggiungti(se non sono diponibili, etc)  */}
      <ConfirmModal
        isOpen={addToCartModalOpen}
        onRequestClose={toggleAddToCartModalOpen}
        onConfirm={() => {
          onAddAll(products, productsQuantities);
          toggleAddToCartModalOpen();
          dispatchAlertChange({
            type: AlertType.Success,
            open: true,
            message: 'Prodotti aggiunti al carrello!',
          });
          setIsAddToCartDisabled(true);
        }}
        title={t('QUOTE:ADD_TO_CART')}
        message={t('QUOTE:ADD_ALL_PRODUCTS_TO_CART')}
        confirmText={t('QUOTE:ADD_BTN')}
      />
    </div>
  );
};

export default ProductsListDetailPresentational;
