import { useTranslation } from 'react-i18next';
import { StrapiListEntityResponse } from '../../lib/interfaces/ICommons';
import PurchasedProductRow from './PurchasedProductRow';
import { IPurchasedProduct } from '../../lib/fetch/orderItems';
import MessageAlert from '../../components/Alert/MessageAlert';

interface Props {
  purchasedProducts: StrapiListEntityResponse<IPurchasedProduct>;
}

const PurchasedProductsTable: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation('PURCHASED_PRODUCTS');
  const { purchasedProducts } = props;

  if (purchasedProducts.data.length === 0)
    return (
      <div className="my-4 mx-auto w-3/4 text-center justify-center">
        <MessageAlert text={t('COMMON:NO_DATA')} />
      </div>
    );

  return (
    <div className="flex flex-col my-4">
      <div className="overflow-x-auto">
        <div className="min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative py-3 pl-2">
                    <span className="sr-only" />
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRODUCT_CODE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ORDERED_BY')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('STATUS')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ORDERED_DATE')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {purchasedProducts?.data.map((_purchasedProduct) => (
                  <PurchasedProductRow purchasedProduct={_purchasedProduct} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchasedProductsTable;
