import { useAuth } from '../../providers/AuthProvider';
import ShoppingCartPresentational from './ShoppingCartPresentational';
import { useCart } from '../../providers/CartProvider';

const ShoppingCart = () => {
  const [{ user }] = useAuth();
  let { addresses } = user!;
  addresses = addresses || [];
  const {
    cartState: { cart },
  } = useCart();
  const { shipping_address } = cart || {};
  let shippingAddressId = shipping_address && shipping_address.id;
  const isShippingAddressIdValid = addresses.some(({ id }) => id === shippingAddressId);
  const deliveryAddressId = isShippingAddressIdValid ? shippingAddressId : undefined;

  return (
    <>
      {cart && (
        <ShoppingCartPresentational cart={cart} deliveryAddressId={deliveryAddressId || undefined} readOnly={false} />
      )}
    </>
  );
};

export default ShoppingCart;
