import { ICartItem } from '../fetch/cartItems';
import { ConditionType, EffectType, IPromotion, IPromotionTier, PromoType } from '../fetch/products';
import currency from './currency';

export const getPromotionTypeCode = (promotionType: string) => {
  const PROMOTION_TYPE_REGEX = /id:(.*?)##/g;
  const promotionCode = PROMOTION_TYPE_REGEX.exec(promotionType);

  return promotionCode ? promotionCode[0] : null;
};

export const getPromoDescription = (promo: IPromotion, lang: string): string => {
  const { mainPromoTiers } = promo;
  let description = '<ul style="list-style: inside;">';

  switch (promo.type) {
    case PromoType.CTS:
      mainPromoTiers.forEach((tier) => {
        const { condition, effect } = tier;
        let firstPart = '';
        let secondPart = '';

        switch (condition.type) {
          case ConditionType.NUMERO_PEZZI:
            firstPart = `Per ogni ${condition.quantity} pz acquistati di questo articolo, riceverai `;
            break;
          case ConditionType.VOLUME_SPESA:
            firstPart = `Per ogni ${condition.priceVolume} spesi per questo articolo, riceverai `;
            break;
          default:
            break;
        }

        switch (effect.type) {
          case EffectType.SCONTO_PERCENTUALE:
            secondPart = `un extrasconto del ${effect.discountPercentage}%</li>`;
            break;
          case EffectType.PRODOTTI_REGALO:
            secondPart = `${effect.giftQuantity} pz in omaggio di ${effect.giftProducts[0]?.code || 'prodotto'}</li>`;
            break;
          default:
            break;
        }
        description += `<li>${firstPart}${secondPart}</li>`;
      });
      break;
    case PromoType.EXTRA_SCONTO:
      mainPromoTiers.forEach((tier) => {
        const { condition, effect } = tier;
        let firstPart = '';
        let secondPart = '';

        switch (condition.type) {
          case ConditionType.NUMERO_PEZZI:
            firstPart = `All'acquisto di  ${condition.quantity} pz di questo articolo, riceverai `;
            break;
          case ConditionType.VOLUME_SPESA:
            firstPart = `Una volta spesi ${condition.priceVolume} per questo articolo, riceverai `;
            break;
          default:
            break;
        }

        switch (effect.type) {
          case EffectType.SCONTO_PERCENTUALE:
            secondPart = `un extrasconto del ${effect.discountPercentage}%</li>`;
            break;
          case EffectType.PRODOTTI_REGALO:
            secondPart = `${effect.giftQuantity} pz in omaggio di ${effect.giftProducts[0]?.code || 'prodotto'}</li>`;
            break;
          default:
            break;
        }

        description += `<li>${firstPart}${secondPart}</li>`;
      });
      break;
    default:
      break;
  }

  description += '</ul>';
  return description;
};

export const getCartItemList = (cartItem: ICartItem) => {
  // Netto nel carrello
  const {
    quantity,
    product: { price_list },
  } = cartItem;

  return price_list ? `${currency(price_list)} (${quantity}x)` : '-';
};

export const getCartItemNet = (cartItem: ICartItem) => {
  // Netto nel carrello
  const { quantity } = cartItem;

  const productPrice = cartItem.price;
  return `${currency(productPrice)} (${quantity}x)`;
};

export const getCartItemFinal = (cartItem: ICartItem) => {
  //Finale nel carrello

  const { quantity, price } = cartItem;

  const productPrice = price;

  return `${currency(productPrice * quantity)}`;
};

/**
 * ritorna la percentuale del prezzo (price) scontato rispetto a quello originale (original_price)
 *
 * @param price
 * @param original_price
 * @returns
 */

export const getDiscountedPricePercentage = (price: number, original_price: number): number => {
  return Math.round(((100 - (price / original_price) * 100) * 100) / 100);
};

export const findTier = (promotion: IPromotion, quantity: number, price: number): IPromotionTier | null => {
  let finalTier: IPromotionTier | null = null;

  promotion.mainPromoTiers.forEach((tier) => {
    switch (tier.condition.type) {
      case ConditionType.NUMERO_PEZZI:
        if (quantity >= tier.condition.quantity! && tier.condition.quantity! > finalTier?.condition.quantity!)
          finalTier = tier;
        break;
      case ConditionType.VOLUME_SPESA:
        const totalSpent = quantity * price;
        if (
          totalSpent >= tier.condition.priceVolume! &&
          tier.condition.priceVolume! > finalTier?.condition.priceVolume!
        )
          finalTier = tier;
        break;
      default:
        break;
    }
  });
  return finalTier;
};
