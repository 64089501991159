import { useQuery } from 'react-query';
import { useAlert } from '../../../providers/AlertProvider';
import { useAuth } from '../../../providers/AuthProvider';
import { useEffect } from 'react';
import FullScreenLoading from '../../../components/FullScreenLoading';
import { useTranslation } from 'react-i18next';
import MessageAlert from '../../../components/Alert/MessageAlert';
import i18n from '../../../i18n';
import { Sidebar } from '../../../components/Sidebar';
import fetchPromotions from '../../../lib/fetch/promotions/list';
import { IFrameBannerProfileBottom } from '../../../components/IFrameBanner/ProfileBottom';
import { AxiosResponse } from 'axios';
import { IPromotion } from '../../../lib/fetch/products';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { getMediaUrl } from '../../../lib/utils';

const PromotionsList = () => {
  const [{ token }] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('COMMON');

  const onSuccess = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onError = () => {
    dispatchAlertChange({ open: true });
  };
  const {
    data: promotions,
    isError,
    isLoading,
    refetch,
  } = useQuery<AxiosResponse<IPromotion[]>>('promotions', () => fetchPromotions(token), {
    onSuccess,
    onError,
  });

  const lang = i18n.languages[0];
  useEffect(() => {
    refetch();
  }, [lang]);

  const steps = [
    {
      name: 'Promotions',
      to: ROUTES.PROMOTIONS.LIST,
      current: true,
    },
  ];
  return (
    <div className="flex flex-col">
      <Breadcrumbs steps={steps} />
      {isError && (
        <div className="flex-grow flex items-center justify-center py-6 px-4 lg:pr-0">
          <div className="mx-auto max-w-xl text-center">
            <MessageAlert text={t('GENERIC_ERROR')} />
          </div>
        </div>
      )}
      <div className="flex-grow mx-auto w-full px-4">
        <div className="min-h-screen min-w-0 flex-1 bg-white lg:flex lg:flex-row">
          {isLoading && <FullScreenLoading loading title={t('LOADING')} subtitle={t('LOADING_SUBTITLE')} />}

          <div className="flex flex-col lg:flex-row w-full">
            <div className="w-full lg:w-max px-4 order-2 lg:order-1">
              <Sidebar />
            </div>

            <div className="w-full pt-6 order-1 lg:order-2 flex-grow">
              <div className="flex flex-col ml-4 h-full">
                {promotions && (
                  <div className="flex-grow">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      {promotions?.data.map((promotion, index) => (
                        <Link to={`${ROUTES.PROMOTIONS.PROMO}/${promotion.id}`} key={index}>
                          <div className="flex flex-col gap-4">
                            <div className="relative rounded-lg overflow-hidden shadow-md">
                              <img
                                src={getMediaUrl(promotion.image?.url)}
                                alt={promotion.image?.name || 'Promotion Image'}
                                className="w-full h-48 object-cover"
                              />
                              <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/70 to-transparent">
                                <div className="bg-white/90 text-gray-800 px-3 py-1 rounded-full text-[10px] sm:text-xs inline-block mb-1">
                                  <span className="mx-1">Dal</span>
                                  <span className="font-bold">
                                    {new Date(promotion.validFrom).toLocaleDateString()}
                                  </span>
                                  <span className="mx-1">al</span>
                                  <span className="font-bold">{new Date(promotion.validTo).toLocaleDateString()}</span>
                                </div>
                                <h3 className="font-bold text-lg text-white">{promotion.title}</h3>
                              </div>
                            </div>
                            <div className="text-gray-800">
                              <p className="text-sm text-gray-600 line-clamp-2">{promotion.description}</p>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
                <IFrameBannerProfileBottom />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionsList;
