import axios from 'axios';

const { REACT_APP_BACKEND_URL } = process.env;

const axiosInstance = axios.create();

interface Params {
  id: number;
  token: string;
}

const acceptFreeCartItemTerms = async (params: Params): Promise<void> => {
  const { id, token } = params;
  await axiosInstance.post(
    `${REACT_APP_BACKEND_URL!}/cart-items/${id}/accept-terms`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export default acceptFreeCartItemTerms;
