import { ITotals } from '../../components/Subtotal';
import { ICartItem } from '../fetch/cartItems';
import { ITecdocManufacturer } from '../fetch/manufacturers';
import { IOrder } from '../fetch/orders';
import { create as _createOrderItem, IOrderItem, IOrderItemCreate } from '../fetch/orderItems';
import { IAddress } from '../interfaces';
import { getTecdocManufacturerById, getTecdocManufacturerByName } from './manufacturers';
import { COUNTRIES } from './index';

export const createOrderItem = async (
  token: string,
  lang: string,
  cartItem: ICartItem,
  tecdocManufacturers: ITecdocManufacturer[],
) => {
  const manufacturerLogo = (
    cartItem.product.tecdoc?.dataSupplierId
      ? getTecdocManufacturerById(cartItem.product.tecdoc?.dataSupplierId, tecdocManufacturers)
      : getTecdocManufacturerByName(cartItem.product.manufacturer.name, tecdocManufacturers)
  )?.dataSupplierLogo.imageURL800;

  const orderItem = {
    productId: cartItem.product.id,
    productCode: cartItem.product.code,
    productEan: cartItem.product.ean,
    // productClassId: cartItem.product.class.id,
    // productClassName: cartItem.product.class.name,
    // productFamilyId: cartItem.product.family.id,
    // productFamilyName: cartItem.product.family.name,
    // productManufacturerId: cartItem.product.manufacturer.id,
    // productManufacturerName: cartItem.product.manufacturer.name,
    productManufacturerLogo: manufacturerLogo,
    productThumbnail: cartItem.product.tecdoc?.images[0]?.imageURL3200 || cartItem.product.thumbnail,
    productName: cartItem.product.description,
    /*     productStandardDescription:
      cartItem.product.tecdoc?.genericArticles[cartItem.product.tecdoc?.genericArticles.length - 1]
        ?.genericArticleDescription ||
      cartItem.product.description ||
      cartItem.product.family.name, */
    productStandardDescription: cartItem.product.description,
    quantity: cartItem.quantity,
    price: cartItem.price,
    discount: cartItem.discount,
    taxes: cartItem.taxes,
    shipping: cartItem.shipping,
    promotion: cartItem.promotion,
    promo_code: cartItem.promotion ? cartItem.promotion.code : null,
  } as IOrderItemCreate;

  return _createOrderItem(token, orderItem);
};

export const calculateOrderTotals = (orderItems: IOrderItem[], shipping?: number): ITotals => {
  const subTotal = orderItems.reduce((acc, orderItem) => {
    const { quantity, price } = orderItem;
    return acc + (quantity * (price || 0) || 0) || 0;
  }, 0);
  const totalDiscount = orderItems.reduce((acc, orderItem) => {
    const { discount, quantity } = orderItem;
    return acc + (quantity * (discount || 0) || 0) || 0;
  }, 0);
  const totalTaxes = orderItems.reduce((acc, orderItem) => {
    const { quantity, taxes } = orderItem;
    return acc + (quantity * (taxes || 0) || 0) || 0;
  }, 0);
  const totalShipping =
    typeof shipping === 'number'
      ? shipping
      : orderItems.reduce((acc, orderItem) => {
          const { quantity, shipping } = orderItem;
          return acc + (quantity * (shipping || 0) || 0) || 0;
        }, 0);
  const subTotalNet = orderItems.reduce((acc, orderItem) => {
    const { quantity, price } = orderItem;
    return acc + (quantity * (price || 0) || 0) || 0;
  }, 0);
  const total = subTotalNet + totalTaxes + totalShipping;

  return {
    subTotal,
    totalDiscount,
    totalTaxes,
    totalShipping,
    subTotalNet,
    total,
  };
};

export const ORDER_FILTER_MONTHS = Object.freeze([
  { id: 1, name: 'LAST_MONTH' },
  { id: 2, name: 'LAST_3_MONTHS' },
  { id: 3, name: 'LAST_6_MONTHS' },
  { id: 4, name: 'LAST_YEAR' },
  { id: 5, name: 'LAST_2_YEARS' },
  { id: 6, name: 'ALL' },
]);

export const getDateFromFilter = (dateFilterId?: number | null) => {
  const today = new Date();

  switch (dateFilterId) {
    case 1:
      return new Date(new Date().setMonth(today.getMonth() - 1)).toISOString();
    case 2:
      return new Date(new Date().setMonth(today.getMonth() - 3)).toISOString();
    case 3:
      return new Date(new Date().setMonth(today.getMonth() - 6)).toISOString();
    case 4:
      return new Date(new Date().setFullYear(today.getFullYear() - 1)).toISOString();
    case 5:
      return new Date(new Date().setFullYear(today.getFullYear() - 2)).toISOString();
    case 6:
    default:
      return;
  }
};

export const getOrderShippingAddress = (order: IOrder): IAddress => ({
  ...order?.attributes?.ord_ship_address?.data,
  ...order?.attributes?.ord_ship_address?.data?.attributes,
});

export const getOrderTaxName = (order: IOrder) => {
  const countryCode = order?.attributes?.ord_ship_address?.data?.attributes?.country;
  return countryCode && COUNTRIES.find((country) => country.value === countryCode)?.taxName;
};
