import { FC, useState } from 'react';
import { IProduct, IPromotion } from '../../../lib/fetch/products';
import Table, { IProductQuantity, Pagination } from '../../../components/ProductsTable';
import { PrimaryButton } from '../../../components/Buttons';
import FullScreenLoading from '../../../components/FullScreenLoading';
import { t } from 'i18next';
import PromotionHeader from './PromotionHeader';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { ROUTES } from '../../../routes';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { useCart } from '../../../providers/CartProvider';

interface IPromotionPresentational {
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  onFetchCrossReferences?: (productId: number) => Promise<IProduct[]>;
  products?: IProduct[];
  page: number;
  pageCount?: number;
  pageSize: number;
  total?: number;
  adding: boolean;
  promotion?: IPromotion;
}

const PromotionPresentational: FC<IPromotionPresentational> = (props) => {
  const {
    onPageChange = () => {},
    onPageSizeChange = () => {},
    onFetchCrossReferences,
    products = [],
    page = 1,
    pageCount = 1,
    pageSize = 0,
    total = 0,
    adding = false,
    promotion,
  } = props;
  const { onAddAll } = useCart();
  const [productsQuantities, setProductsQuantities] = useState<IProductQuantity[]>();
  const steps = [
    {
      name: 'Promotions',
      to: ROUTES.PROMOTIONS.LIST,
    },
    {
      name: String(promotion?.title || '-'),
      to: ROUTES.PROMOTIONS.PROMO + '/' + promotion?.id,
    },
  ];
  return (
    <div>
      <Breadcrumbs steps={steps} />
      {adding && (
        <FullScreenLoading
          loading={adding}
          title={t('SHOPPING_CART:UPDATING_TITLE')}
          subtitle={t('SHOPPING_CART:UPDATING_SUBTITLE')}
          height={200}
          width={400}
        />
      )}

      <div className="container mx-auto px-4 py-4">
        <div className="mb-8">{promotion && <PromotionHeader promotion={promotion} />}</div>
        {products.length > 0 ? (
          <div className="mt-8">
            <div className="flex items-center justify-between mb-6">
              <div className="ml-auto">
                <PrimaryButton
                  onClick={() => onAddAll(products, productsQuantities ?? [])}
                  text={t('PROMOTION_DETAIL:ADD_ALL_TO_CART')}
                  icon={<ShoppingBagIcon className="mr-3 h-5 w-5" aria-hidden="true" />}
                />
              </div>
            </div>
            <Table
              products={products}
              onFetchCrossReferences={onFetchCrossReferences}
              onQuantitiesChange={setProductsQuantities}
            />
            <Pagination
              currentItems={products}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              page={page}
              pageCount={pageCount}
              pageSize={pageSize}
              total={total}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PromotionPresentational;
