import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProduct, IPromotion } from '../../lib/fetch/products';
import { DEFAULT_IMAGE_PLACEHOLDER } from '../../lib/constants';
import { ImageMediaType } from '../../lib/enums';
import { SingleProduct } from './SingleProduct';

export { default as HorizontalFilter } from './HorizontalFilter';
export { default as Pagination } from './Pagination';
export { default as WarehouseTable } from './WarehouseTable';

interface IProductsTable {
  onFetchCrossReferences?: (productId: number) => Promise<IProduct[]>;
  products: IProduct[];
  onQuantitiesChange?: (productQuantities: IProductQuantity[]) => void;
}

export interface IProductQuantity {
  id: number;
  quantity: number;
  assortedQuantity?: number;
  touched?: boolean;
}

export const isBackgroundCompatible = (thumbnail: string) => {
  const url = (thumbnail || '').split('?').shift();
  const ext = (url || '').split('.').pop();
  return ([ImageMediaType.JPG, ImageMediaType.JPEG, ImageMediaType.PNG] as string[]).includes(
    (ext || '').toUpperCase(),
  );
};
export const getThumbnail = (thumbnail?: string) => {
  if (!thumbnail)
    return (
      <div
        className="h-12 w-12 border border-gray-200 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${DEFAULT_IMAGE_PLACEHOLDER})`,
          minWidth: '3rem',
        }}
      />
    );
  if (isBackgroundCompatible(thumbnail))
    return (
      <div
        className="h-12 w-12 border border-gray-200 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${encodeURI(thumbnail)})`,
          minWidth: '3rem',
        }}
      />
    );
  return <img className="h-12 w-12" style={{ minWidth: '3rem' }} src={encodeURI(thumbnail)} alt="thumbnail" />;
};
const initializeQuantities = (products: IProduct[]): IProductQuantity[] =>
  products.map((product) => ({
    id: product.id,
    quantity: product?.attributes?.sales_package_sizes ? Math.floor(product?.attributes?.sales_package_sizes) : 1,
    assortedQuantity: undefined,
    touched: false,
  }));

const ProductsTable: FC<IProductsTable> = (props) => {
  const { onFetchCrossReferences, products = [], onQuantitiesChange } = props;
  const { t } = useTranslation('PRODUCTS');

  const [productQuantities, setProductQuantities] = useState<IProductQuantity[]>(initializeQuantities(products));

  useEffect(() => {
    const initialQuantities = initializeQuantities(products);
    setProductQuantities(initialQuantities);
    onQuantitiesChange && onQuantitiesChange(initialQuantities);
  }, [products, onQuantitiesChange]);

  const onQuantityChange = (quantity: IProductQuantity) => {
    let newProductQuantities = [...productQuantities];
    const existingQuantity = newProductQuantities.find((q) => q.id === quantity.id);
    if (existingQuantity) {
      existingQuantity.quantity = quantity.quantity;
    } else {
      newProductQuantities.push(quantity);
    }
    setProductQuantities(newProductQuantities);
    if (onQuantitiesChange) {
      onQuantitiesChange(newProductQuantities);
    }
  };

  const promotions: Map<number, IPromotion> = new Map();
  for (const product of products) {
    const promotion = product.attributes?.promotion;
    promotion && promotions.set(promotion.id, promotion);
  }

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative py-3 pl-2">
                    <span className="sr-only" />
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRODUCT')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('BRAND')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('DELIVERY')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('STOCK')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE_NET')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('FINAL_PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ACTION')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {products.map((product) => {
                  const productQuantity = productQuantities.find((q) => q.id === product.id);
                  return (
                    <SingleProduct
                      key={product.id}
                      product={product}
                      onFetchCrossReferences={onFetchCrossReferences}
                      onQuantityChange={onQuantityChange}
                      assortedQuantity={productQuantity?.assortedQuantity || 0}
                      touched={productQuantity?.touched}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsTable;
