import { FC } from 'react';
import { Link } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/24/solid';
import queryString from 'query-string';
import { SORTING_OPTIONS } from '../../components/ProductsTable/HorizontalFilter';
import { useTranslation } from 'react-i18next';
import { NewsPostCategory } from '../../lib/models/NewsPost';
import { ROUTES } from '../../routes';

interface IBreadcrumbs {
  categoryName: string | undefined;
  setSelectedCategory: (category: NewsPostCategory | null) => void;
}

export const NewsBreadcrumb: FC<IBreadcrumbs> = (props) => {
  const { categoryName } = props;
  const { t } = useTranslation('COMMON');

  const steps = [
    {
      name: t('NEWS_CATEGORIES') || '-',
      onClick: () => props.setSelectedCategory(null),
      current: categoryName ? false : true,
    },
    ...(categoryName
      ? [
          {
            name: categoryName,
            onClick: null,
            current: true,
          },
        ]
      : []),
  ];
  return (
    <nav className="flex pt-3 pl-4 pb-6" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link
              to={`${ROUTES.SEARCH.SEARCH_RESULTS}?${queryString.stringify({
                sort: SORTING_OPTIONS[0].value,
              })}`}
              className="text-gray-400 hover:text-gray-500"
            >
              <HomeIcon className="h-4 w-4 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {steps.map(({ current, name, onClick }) => (
          <li key={name}>
            <div className="flex items-center">
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <p
                className={`ml-2 text-xs font-medium text-gray-500 ${onClick ? 'cursor-pointer hover:text-gray-700 ' : ''}`}
                aria-current={current ? 'page' : undefined}
                onClick={onClick ? onClick : undefined}
              >
                {name}
              </p>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
