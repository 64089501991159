import { useTranslation } from 'react-i18next';
import { PrimaryButtonFull } from '../../../../components/Buttons';
import { InputPresentational } from '../../../../components/Form';
import { useFormContext } from 'react-hook-form';
import IResetPasswordInAppForm from './interfaces/resetPasswordInAppForm';
import { formatFormErrorMessage } from '../../../../lib/utils/formatters';
import { classNames } from '../../../../lib/utils';

interface Props {
  onSubmit: (formValues: IResetPasswordInAppForm) => void;
  isLoading: boolean;
  className?: string;
}

const ResetPasswordInAppForm: React.FC<Props> = (props) => {
  const { t } = useTranslation('');
  const { onSubmit, isLoading, className } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IResetPasswordInAppForm>();

  return (
    <form className={classNames('w-full lg:w-1/2 xl:w-1/3 space-y-4', className)} onSubmit={handleSubmit(onSubmit)}>
      <InputPresentational
        label={t('SECURITY:CURRENT_PASSWORD')}
        id="oldPassword"
        type="password"
        autoComplete="old-password"
        {...register('oldPassword')}
        hasError={!!errors.oldPassword?.message}
        helperText={t(formatFormErrorMessage(errors.oldPassword?.message))}
      />
      <InputPresentational
        label={t('SECURITY:NEW_PASSWORD')}
        id="newPassword"
        type="password"
        autoComplete="new-password"
        {...register('newPassword')}
        hasError={!!errors.newPassword?.message}
        helperText={t(formatFormErrorMessage(errors.newPassword?.message))}
      />
      <InputPresentational
        label={t('SECURITY:REPEAT_NEW_PASSWORD')}
        id="repeatNewPassword"
        type="password"
        autoComplete="repeat-new-password"
        {...register('repeatNewPassword')}
        hasError={!!errors.repeatNewPassword?.message}
        helperText={t(formatFormErrorMessage(errors.repeatNewPassword?.message))}
      />
      <div className="my-4">
        <PrimaryButtonFull type="submit" loading={isLoading} text={t('SECURITY:CONFIRM')} />
      </div>
    </form>
  );
};

export default ResetPasswordInAppForm;
