import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { CartStatus } from '../../../lib/fetch/carts';

interface CartsFiltersProps {
  filters: {
    businessName: string;
    status?: CartStatus;
  };
  onFilterChange: (filterName: string, value: string) => void;
}

const CartsFilters: React.FC<CartsFiltersProps> = ({ filters, onFilterChange }) => {
  const { t } = useTranslation('IMPERSONATION');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    onFilterChange(e.target.name, e.target.value);
  };

  return (
    <div className="flex items-center my-4 justify-end">
      <span className="text-gray-500 mr-2">{t('FILTER_BY')}</span>
      <div className="flex space-x-2">
        <input
          type="text"
          name={'businessName'}
          value={filters.businessName}
          onChange={handleInputChange}
          placeholder={t('BUSINESS_NAME')}
          className="bg-gray-100 text-black rounded-lg py-1 px-3 border-none"
        />
        <div className="bg-gray-100 text-black rounded-lg px-1 flex items-center">
          <select
            name="status"
            value={filters.status}
            onChange={handleInputChange}
            className="bg-transparent outline-none border-none"
          >
            <option value={''}>{t('ALL')}</option>
            <option value={CartStatus.APPROVED}>{t('APPROVED')}</option>
            <option value={CartStatus.REJECTED}>{t('DISAPPROVED')}</option>
            <option value={CartStatus.PENDING}>{t('PENDING')}</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default CartsFilters;
