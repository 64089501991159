import { IUser } from '../../../lib/interfaces';

type Params = { user?: IUser };

const getCanBuyOutOfStock = (params: Params): boolean => {
  const { user } = params;

  const canBuyOutOfStockUser = user?.canBuyOutOfStock;
  const canBuyOutOfStockCustomer = user?.customer?.canBuyOutOfStock;

  const canBuyOutOfStock = Boolean(canBuyOutOfStockUser && canBuyOutOfStockCustomer);

  return canBuyOutOfStock;
};

export default getCanBuyOutOfStock;
