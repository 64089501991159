import axios, { AxiosResponse } from 'axios';
import { ICartItemCreate, ICartItemCreateResult } from './index';

const { REACT_APP_CART_ITEMS_URL } = process.env;

export interface ICartItemUpdate extends Omit<ICartItemCreate, 'price'> {
  id: number;
}

export interface ICartItemUpdateResult extends ICartItemCreateResult {}

const update = async (token: string, cartItem: ICartItemUpdate) => {
  const response = await axios.put<{ data: ICartItemUpdateResult }>(
    `${REACT_APP_CART_ITEMS_URL!}/${cartItem.id}`,
    { data: cartItem },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default update;
