import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { ArrowRightEndOnRectangleIcon } from '@heroicons/react/24/outline';
import { AuthActionType, useAuth } from '../../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import IAgent from '../../../lib/interfaces/IAgent';
import setAgent from '../../../lib/fetch/rva/setAgent';
import { useTranslation } from 'react-i18next';

const { REACT_APP_RVA_GET_AGENTS_URL } = process.env;

const AgentList = () => {
  const [data, setData] = useState<IAgent[]>([]);
  const { t } = useTranslation('IMPERSONATION');

  const navigate = useNavigate();
  const [{ token }, dispatchAuthChange] = useAuth();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(REACT_APP_RVA_GET_AGENTS_URL!, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data ?? []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const impersonateAgent = async (agentId: number) => {
    const { data: updatedUser } = await setAgent(token!, agentId);
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
    navigate('/agent');
  };

  return (
    <div className="overflow-x-auto m-8">
      <table className="min-w-full bg-white rounded-t-lg overflow-hidden">
        <thead className="bg-gray-50 text-gray-500">
          <tr className="rounded">
            <th className="px-4 py-2">{t('NAME')}</th>
            <th className="px-4 py-2">{t('EMAIL')}</th>
            <th className="px-4 py-2">{t('VAT')}</th>
            <th className="px-4 py-2">{t('ACTIONS')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="border">
              <td className="px-4 py-2 text-center">
                <p className="font-bold">{item.name}</p>
              </td>
              <td className="px-4 py-2 text-sm text-gray-400">
                <div className="flex justify-center my-2">
                  {item.email ? <EnvelopeIcon className="h-4 w-4 mr-1 mt-1 flex-shrink-0" /> : <></>}
                  {item.email}
                </div>
              </td>
              <td className="px-4 py-2 text-sm text-gray-400">
                <div className="flex justify-center my-2">{item.vat_number}</div>
              </td>
              <td className="px-4 py-2 flex justify-center">
                <button className="bg-red-500 text-white rounded-md p-2" onClick={() => impersonateAgent(item.id)}>
                  <ArrowRightEndOnRectangleIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AgentList;
