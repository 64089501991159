import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ICartItem } from '../../../lib/fetch/cartItems';
import { currency } from '../../../lib/utils';
import { getCartItemFinal, getCartItemNet, getDiscountedPricePercentage } from '../../../lib/utils/promotions';
import { getIsCartItemDiscounted } from '../../../lib/utils/cart';

interface IExtendedPriceField {
  cartItem: ICartItem;
}

const ExtendedPriceField: FC<IExtendedPriceField> = (props) => {
  const { cartItem } = props;
  const { price, product, quantity, original_price } = cartItem || {};
  const { t } = useTranslation('PRODUCTS');

  return (
    <>
      <div className="flex flex-row gap-3">
        <div className="flex flex-col text-gray-400">
          <span className="text-xs font-light ">{t('LIST')}:</span>
          <span className="text-sm">{product.price_list ? `${currency(product.price_list)} (${quantity}x)` : '-'}</span>
        </div>

        {getIsCartItemDiscounted(cartItem) ? (
          <div className="flex flex-col text-gray-400">
            <span className="text-xs font-light ">{t('DISCOUNT')}:</span>
            <span className="text-sm">{`${currency((original_price - price) * quantity)}  (${getDiscountedPricePercentage(price, original_price)}%)`}</span>
          </div>
        ) : null}

        <div className="flex flex-col text-gray-400">
          <span className="text-xs font-light">{t('NET')}:</span>
          {getIsCartItemDiscounted(cartItem) && (
            <span className="line-through font-light text-gray-400">{currency(original_price)}</span>
          )}
          <span className="text-sm">{getCartItemNet(cartItem)}</span>
        </div>

        <div className="flex flex-col">
          <span className="text-xs font-light text-gray-400">{t('FINAL')}:</span>
          {getIsCartItemDiscounted(cartItem) && (
            <p className="text-sm line-through font-light text-gray-500">{currency(original_price * quantity)}</p>
          )}
          <span className="text-sm text-gray-700">{getCartItemFinal(cartItem)}</span>
        </div>
      </div>
    </>
  );
};

export default ExtendedPriceField;
