import { useTranslation } from 'react-i18next';
import PiecesBadge from '../StockBadge/PiecesBadge';

interface IWarehouseTableProps {
  otherWarehouses: {
    warehouse_name: string;
    warehouse_id: number;
    warehouse_code: string;
    quantity: number;
  }[];
}

export default function WarehouseTable({ otherWarehouses }: IWarehouseTableProps) {
  const { t } = useTranslation('PRODUCTS');
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('WAREHOUSE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('STOCK')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('DELIVERY')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ORDER_EXPIRATION')}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {otherWarehouses.map((warehouse) => (
                  <tr key={warehouse.warehouse_id}>
                    <td className="whitespace-nowrap px-4 py-2">
                      <div className="text-sm font-medium text-gray-900">{warehouse.warehouse_name}</div>
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-500">
                      <PiecesBadge quantity={warehouse.quantity} />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2">
                      <div className="text-sm text-yellow-700">2 giorni lavorativi</div>
                    </td>
                    <td className="whitespace-nowrap px-4 py-2">
                      <div className="text-sm font-medium text-gray-900">18:00</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
