import { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/solid';
import { MinusIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { SingleWordButtonPrimary } from '../../Buttons';
import { ICartItem } from '../../../lib/fetch/cartItems';
import { DEFAULT_IMAGE_PLACEHOLDER, HIDDEN_MANUFACTURERS_NAMES } from '../../../lib/constants';
import { isBackgroundCompatible } from '../../ProductsTable';
import { currency, quantityInputProps } from '../../../lib/utils';
import { IProduct } from '../../../lib/fetch/products';
import { getTecdocManufacturerById, getTecdocManufacturerByName } from '../../../lib/utils/manufacturers';
import { useSettings } from '../../../providers/SettingsProvider';
import { getCartItemFinal, getPromoDescription } from '../../../lib/utils/promotions';
import { useAuth } from '../../../providers/AuthProvider';
import { useCart } from '../../../providers/CartProvider';
import { ROUTES } from '../../../routes';
import { getIsCartItemDiscounted } from '../../../lib/utils/cart';

const getThumbnail = (thumbnail?: string) => {
  if (!thumbnail)
    return (
      <div
        className="h-28 w-24 rounded-md bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${DEFAULT_IMAGE_PLACEHOLDER})`,
          minWidth: '6rem',
        }}
      />
    );
  if (isBackgroundCompatible(thumbnail))
    return (
      <div
        className="h-28 w-24 rounded-md bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${encodeURI(thumbnail)})`,
          minWidth: '6rem',
        }}
      />
    );
  return <img className="h-28 w-24" style={{ minWidth: '6rem' }} src={encodeURI(thumbnail)} alt="" />;
};

interface ISingleProduct {
  cartItem: ICartItem;
}

const SingleProduct: FC<ISingleProduct> = (props) => {
  const [{ user }] = useAuth();
  const { cartItem } = props;
  const [{ tecdocManufacturers }] = useSettings();
  const { promotion, price, promoApplication, product, original_price, free, messages } = cartItem;
  const cartItemQuantity = +cartItem.quantity || 0;
  const [quantity, setQuantity] = useState(cartItemQuantity);
  const { manufacturer } = product;
  const {
    max: maxQuantity,
    min: minQuantity,
    disabled: isUnavailable,
  } = quantityInputProps({ attributes: { ...product, price_net: price } } as unknown as IProduct, user);
  const {
    t,
    i18n: { language },
  } = useTranslation('PRODUCTS');

  const { onItemRemove, onItemQuantityChange } = useCart();

  const { handleSubmit, register, setValue, watch } = useForm({
    defaultValues: { quantity },
  });
  const onQuantityChange = useDebouncedCallback(onItemQuantityChange, 1000);
  const onSubmit = handleSubmit(({ quantity: newQuantity }) => {
    setQuantity(+newQuantity || quantity);
  });

  useEffect(() => {
    setValue('quantity', quantity);
    quantity !== cartItemQuantity && onQuantityChange({ cartItem, newQuantity: quantity });
  }, [quantity]);

  useEffect(() => {
    setValue('quantity', cartItemQuantity);
  }, [cartItemQuantity]);

  useEffect(() => {
    if (cartItem.quantity === quantity) return;
    setQuantity(+cartItem.quantity || 0);
  }, [cartItem]);

  useEffect(
    () =>
      watch((formData, { name, type }) => {
        name === 'quantity' && type === 'change' && onSubmit();
      }).unsubscribe,
    [watch],
  );

  const productPrice = useMemo(() => {
    return Number(original_price || 0) - Number(price || 0);
  }, [original_price, original_price]);

  return (
    <div className="flex space-x-3 pt-6 text-gray-400">
      {getThumbnail(product.tecdoc?.images[0]?.imageURL3200 || product.thumbnail)}
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-col justify-between">
          <div className="space-y-1">
            <h3 className="text-base font-bold text-gray-700">
              <Link to={`${ROUTES.SEARCH.PRODUCT_DETAIL}/${product.id}`}>{product.code}</Link>
            </h3>
            <p className="text-xs text-gray-400">
              {product.description ||
                product.tecdoc?.genericArticles[product.tecdoc?.genericArticles.length - 1].genericArticleDescription ||
                product.family?.name}
            </p>
            {product.tecdoc?.dataSupplierId &&
            getTecdocManufacturerById(product.tecdoc?.dataSupplierId, tecdocManufacturers) ? (
              <img
                className="h-auto w-14"
                src={
                  getTecdocManufacturerById(product.tecdoc?.dataSupplierId, tecdocManufacturers)?.dataSupplierLogo
                    .imageURL800
                }
                alt=""
              />
            ) : manufacturer?.name && getTecdocManufacturerByName(manufacturer?.name, tecdocManufacturers) ? (
              <img
                className="h-auto w-14"
                src={getTecdocManufacturerByName(manufacturer?.name, tecdocManufacturers)?.dataSupplierLogo.imageURL800}
                alt=""
              />
            ) : manufacturer?.name ? (
              HIDDEN_MANUFACTURERS_NAMES.includes(manufacturer?.name) ? (
                manufacturer?.id
              ) : (
                manufacturer?.name
              )
            ) : (
              manufacturer?.id
            )}
          </div>
          {!!promotion && price !== 0 && (
            <div className="my-1">
              <p className="text-xs">{t('PROMOTION_AVAILABLE')}:</p>
              <p
                className=" text-xs text-red-500"
                dangerouslySetInnerHTML={{ __html: getPromoDescription(promotion, language) }}
              ></p>
            </div>
          )}
          {!!messages?.length &&
            messages.map((message) => (
              <div className="my-3">
                <div className="rounded-md bg-yellow-50 p-3 shadow-sm">
                  <p className="text-xs text-yellow-700">{t(message)}</p>
                </div>
              </div>
            ))}
          {free && (
            <div className="my-1 flex flex-row gap-1">
              <p className="text-xs">{t('QTY')}:</p>
              <p className="text-xs font-bold">{`${quantity}x`}</p>
            </div>
          )}
          {free && (
            <div className="my-1">
              <p className="text-xs text-red-500">{t('FREE_PRODUCT')}</p>
            </div>
          )}
          {!free && (
            <form className="flex flex-row items-center gap-2" onSubmit={onSubmit}>
              <p className="text-xs">{t('QTY')}:</p>
              <button type="button" disabled={quantity < 2 || price === 0} onClick={() => setQuantity(quantity - 1)}>
                <MinusIcon className="h-3 w-3" />
              </button>
              <input
                type="number"
                className="block w-14 rounded-md border-transparent bg-white text-xs text-gray-900 shadow-sm focus:border-red-500 focus:ring-red-500"
                max={maxQuantity}
                min={minQuantity}
                step="1"
                disabled={isUnavailable || price === 0}
                {...register('quantity', {
                  max: maxQuantity,
                  min: minQuantity,
                  disabled: isUnavailable || price === 0,
                  required: true,
                })}
              />
              <button
                type="button"
                disabled={isUnavailable || quantity >= maxQuantity || price === 0}
                onClick={() => setQuantity(quantity + 1)}
              >
                <PlusIcon className="h-3 w-3" />
              </button>
            </form>
          )}
        </div>
        <div className="flex flex-col items-end justify-between text-right">
          <div className="space-y-4">
            <p className="flex flex-col text-xs">
              {t('NET')}:
              {getIsCartItemDiscounted(cartItem) && (
                <p className="ml-3 line-through text-nowrap">
                  {currency(original_price)} ({quantity}x)
                </p>
              )}
              <span className="ml-3 font-bold text-nowrap">{currency(price)}</span>
            </p>
            {getIsCartItemDiscounted(cartItem) ? (
              <p className="flex flex-col text-xs">
                <span className="text-nowrap">
                  {t('DISCOUNT')}:{' '}
                  <span className="font-bold">{promoApplication?.tier?.effect?.discountPercentage}%</span>
                </span>
                <span className="ml-3 font-bold text-nowrap">{`${currency(productPrice)} (${quantity}x)`}</span>
              </p>
            ) : null}
            <p className="flex flex-col text-xs">
              {t('FINAL')}:
              {getIsCartItemDiscounted(cartItem) && (
                <p className="ml-3 text-base line-through text-gray-600">{currency(original_price * quantity)}</p>
              )}
              <span className="ml-3 text-base font-bold text-gray-900">{getCartItemFinal(cartItem)}</span>
            </p>
          </div>
          {!free && (
            <SingleWordButtonPrimary
              onClick={() => onItemRemove(cartItem)}
              text={t('COMMON:DELETE')}
              disabled={price === 0}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
