import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { IAddToCartWithFeedback, addToCartWithFeedback } from '../../lib/utils/cart';
import { useAuth } from '../../providers/AuthProvider';
import i18n from '../../i18n';
import { SORTING_OPTIONS } from '../ProductsTable/HorizontalFilter';
import { WhereToSearch } from '../../lib/enums';
import { useShoppingCartOverlay } from '../../providers/ShoppingCartOverlayProvider';
import Papa from 'papaparse';
import { IErrorImport, IProductImport, IProductWithQuantity, mergeAndSumProducts } from './utils';
import ImportProductCodesModal from './ImportProductCodesModal';
import ImportProductCodesReportModal from './ImportProductCodesReportModal';
import { exactSearchCode } from '../../lib/fetch/products/search';
import { useCart } from '../../providers/CartProvider';

export interface IImportModalProps {
  open: boolean;
  onClose: () => void;
}

const ImportModal = (props: IImportModalProps) => {
  const { open, onClose } = props;
  const [textareaValue, setTextareaValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
  const [textareaValueError, setTextareaValueError] = useState<boolean>(false);
  const [searchError, setSearchError] = useState<IErrorImport[]>([]);
  const [productsNotAvailable, setProductsNotAvailable] = useState<IErrorImport[]>([]);
  const [productsNotMinQuantity, setProductsNotMinQuantity] = useState<IErrorImport[]>([]);
  const [addedProducts, setAddedProducts] = useState<string[]>([]);
  const [, dispatchAlertChange] = useAlert();
  const lang = i18n.languages[0];
  const { t } = useTranslation('IMPORT');
  const [{ token, user }, dispatchAuthChange] = useAuth();
  const [, dispatchCartOverlayChange] = useShoppingCartOverlay();
  const {
    cartState: { cart },
  } = useCart();

  useEffect(() => {
    if (!open) {
      // Reset handling states
      setTextareaValue('');
      setTextareaValueError(false);
      setLoading(false);
    }
  }, [open]);

  const searchProduct = async (code: string) => {
    const { data: axiosData } = await exactSearchCode(token!, {
      page: 1,
      pageSize: 2, // We just want to know if there is more than ONE product.
      search: code,
      sort: SORTING_OPTIONS[0].value,
      where: WhereToSearch.Code,
      lang,
    });
    const { data = [] } = axiosData;

    // Check if there isn't any product with this code
    if (!data.length) {
      const error = { code: code, error: 0 };
      return { product: null, error };
    }

    // Check if there is more than one product with this code
    if (data.length > 1) {
      const error = { code: code, error: 1 };
      return { product: null, error };
    }

    const [product] = data;
    return { product, error: null };
  };

  const toggleReportModal = () => {
    setIsReportModalOpen((v) => !v);
  };

  const handleAddToCart = async () => {
    setLoading(true);

    // Reset handling states
    setTextareaValueError(false);
    setSearchError([]);
    setProductsNotAvailable([]);
    setAddedProducts([]);
    setProductsNotMinQuantity([]);

    let error = false;

    // Parse Textarea data as CSV
    const codesArray = textareaValue
      .trim()
      .split('\n')
      .filter((code) => code && code.trim() !== '');
    const codesObjArray: IProductImport[] = codesArray.map((code) => {
      const codeNumber = code && code.trim().split(';')[0] ? code.trim().split(';')[0].trim() : '';
      const codeQuantity = code && code.trim().split(';')[1] ? Number(code.trim().split(';')[1].trim()) : 0;

      // Check for formatting errors
      if (!codeNumber || !codeQuantity) {
        setTextareaValueError(true);
        error = true;
      }
      return { code: codeNumber, quantity: codeQuantity };
    });

    if (error || !token || !user) {
      setLoading(false);
      return;
    }

    // Check for max products limit
    if (codesObjArray.length > 100) {
      dispatchAlertChange({
        message: t('MAX_PRODUCTS_LIMIT'),
        open: true,
        type: AlertType.Error,
      });
      setLoading(false);
      return;
    }

    // Merge products with the same code and sum quantities
    const codesObjArrayUnique = mergeAndSumProducts(codesObjArray);

    const products: IProductWithQuantity[] = [];

    let searchError: IErrorImport[] = [];
    let productsNotMinQuantity: IErrorImport[] = [];

    for await (const codeObj of codesObjArrayUnique) {
      const { product, error } = await searchProduct(codeObj.code || '');
      if (product && !error) {
        if (
          product?.attributes?.sales_package_sizes &&
          (product?.attributes?.sales_package_sizes > Number(codeObj.quantity) ||
            (product?.attributes?.sales_package_sizes < Number(codeObj.quantity) &&
              Number(codeObj.quantity) % product.attributes.sales_package_sizes !== 0))
        ) {
          productsNotMinQuantity.push({ code: codeObj.code, error: 2 });
        } else {
          products.push({ product: product, quantity: Number(codeObj.quantity) });
        }
      }

      setProductsNotMinQuantity(productsNotMinQuantity);

      // Error handling: product not found or more results for the same code
      if (error) {
        searchError.push(error);
      }
    }

    // Add warning if product not found or more results for the same code
    if (searchError.length) {
      setSearchError(searchError);
    }

    const result = await addToCartWithFeedback({
      dispatchAlertChange,
      dispatchAuthChange,
      dispatchCartOverlayChange,
      products: products,
      setAdding: setLoading,
      t,
      token,
      user,
      cart,
      lang,
      onClose,
    } as IAddToCartWithFeedback);

    setProductsNotAvailable(result?.productsNotAvailable || []);
    setAddedProducts(result?.availableProducts || []);

    onClose();
    toggleReportModal();
  };

  const onFileChange = async (newFiles: File[]) => {
    setLoading(true);

    // Reset handling states
    setTextareaValueError(false);
    setSearchError([]);

    const file = newFiles[0];
    Papa.parse(file, {
      complete: (results) => {
        setTextareaValue(results.data.join('\n'));

        dispatchAlertChange({
          message: t('FILE_LOAD_SUCCESS'),
          open: true,
          type: AlertType.Success,
        });
      },
      error: () => {
        dispatchAlertChange({
          message: t('FILE_LOAD_ERROR'),
          open: true,
          type: AlertType.Error,
        });
      },
    });

    setLoading(false);
  };

  return (
    <>
      <ImportProductCodesModal
        loading={loading}
        setTextareaValue={setTextareaValue}
        setTextareaValueError={setTextareaValueError}
        onClose={onClose}
        onFileChange={onFileChange}
        open={open}
        handleAddToCart={handleAddToCart}
        textareaValue={textareaValue}
        textareaValueError={textareaValueError}
      />
      <ImportProductCodesReportModal
        loading={loading}
        onClose={toggleReportModal}
        open={isReportModalOpen}
        searchError={searchError}
        productsNotAvailable={productsNotAvailable}
        addedProducts={addedProducts}
        productsNotMinQuantity={productsNotMinQuantity}
      />
    </>
  );
};

export default ImportModal;
