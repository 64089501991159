import moment from 'moment';
import React from 'react';
import { NewsPost } from '../../lib/models/NewsPost';
import Markdown from 'react-markdown';
import { getMediaUrl } from '../../lib/utils';

export interface INewsItemProps {
  news: NewsPost;
  onClick?: () => void;
}

export const NewsItem = (props: INewsItemProps) => {
  const { title, content, image, date } = props.news.attributes;
  const imageUrl = image?.data?.attributes.url;

  return (
    <div className="h-72 cursor-pointer rounded-lg border border-gray-300" onClick={props.onClick}>
      <img src={getMediaUrl(imageUrl)} alt={title} className="mr-auto ml-auto mt-4 mb-5 h-2/4 w-auto" />
      <div className="mb-4 flex justify-between">
        <p className="ml-4 overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold text-gray-600">{title}</p>
        <p className="font-regular text-end mx-4 mb-1 text-xs text-gray-400">{moment(date).format('L')}</p>
      </div>
      <div
        className="font-regular mx-4 mb-1 overflow-hidden text-ellipsis text-sm text-gray-400"
        style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
      >
        <Markdown>{content}</Markdown>
      </div>
    </div>
  );
};
