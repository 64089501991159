export default interface ICustomer {
  id: number;
  customer_id: string;
  active: boolean;
  suspended: boolean;
  customer_class_id?: {
    id: number;
    name: string;
  };
  aastoreEnabled: boolean;
  canBuyOutOfStock: boolean | null;
  sector_id: string;
  list_discount: number;
  usual_discount: number;
  revenue_discount: number;
  increase: number;
  fax: null | string;
  city: null | string;
  name: string;
  telex: null | string;
  phone: null | string;
  address: string;
  vat_num: null | string;
  fiscal_code: null | string;
  zipcode: null | string;
  district: null | string;
  province: null | string;
  country: null | string;
  region_id: number;
  agent_id: number;
  credits: number;
  createdAt: string;
  updatedAt: string;
  shipping_mode: DeliveryType;
  excerpt: boolean;
  status: number;
  consultazione_documenti: boolean;
  isOrdersEmailsAccepted: boolean | null;
  warehouse_id: {
    id: number;
    code: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  };
  fido: number;
}

export enum DeliveryType {
  Bench = 'bench',
  Courier = 'courier',
  Forced_bench = 'forced_bench',
  Mixed = 'mixed',
}
