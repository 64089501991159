export const ROUTES = {
  PROFILE: {
    RETURNS: '/profile/return-info',
    DOCUMENTS: '/profile/documents',
    COMMERCIAL_STATS: '/profile/commercial-stats',
  },
  ORDERS: {
    LIST: '/my-orders',
  },
  PROMOTIONS: {
    LIST: '/promotions',
    PROMO: '/promotion',
  },
  SEARCH: {
    PRODUCT_DETAIL: '/product-detail',
    SEARCH_RESULTS: '/search-results',
  },
  NEWS: {
    LIST: '/news',
  },
  CHECKOUT: {
    PAYMENT: '/checkout-payment',
    SHIPPING: '/checkout-shipping',
    RESULT: '/checkout-result',
  },
};

export const ROUTE_CONFIG = {
  '/cati-b2b/login': { header: false, footer: false },
  '/cati-b2b/question-reset-password': { header: false, footer: true },
  '/cati-b2b/reset-password': { header: false, footer: true },
  '/cati-b2b/forgot-password': { header: false, footer: true },
  '/cati-b2b/forgot-password-feedback': { header: false, footer: true },
  '/cati-b2b/reset': { header: false, footer: true },
  '/cati-b2b/reset-temporary-password': { header: false, footer: true },
  '/cati-b2b/reset-temporary-password-feedback': { header: false, footer: true },
  '/cati-b2b/reset-feedback': { header: false, footer: true },
  '/cati-b2b/redirect': { header: false, footer: false },
  '/cati-b2b/info-new-username': { header: false, footer: true },
  '/forgot': { header: false, footer: false },
  '/reset': { header: false, footer: false },
  '/activation-request': { header: false, footer: false },
  '/thank-you-registration': { header: false, footer: false },
  '/login': { header: false, footer: false },
  '/': { header: false, footer: false },
  '/agent': { header: false, footer: false },
  '/rva': { header: false, footer: false },
  '/agent-cart': { header: false, footer: false },
  '/faq': { header: false, footer: false },
  '/privacy-policy': { header: false, footer: false },
  '/shipping': { header: false, footer: false },
  '/terms-conditions': { header: false, footer: false },
};
