import React, { useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import { useQuery } from 'react-query';
import { LinkType } from '../../lib/models/Link';
import { PaginatedResponse } from '../../lib/models/PaginatedResponse';
import fetchLinks from '../../lib/fetch/links';
import i18n from 'i18next';

const LinksCollapse = () => {
  const { t } = useTranslation('LINKS');
  const [{ token }] = useAuth();

  const { data: links, refetch } = useQuery<PaginatedResponse<LinkType>>(
    'links',
    () => fetchLinks(token, { page: 1, limit: 100, sort: ['text:desc'] }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const lang = i18n.languages[0];
  useEffect(() => {
    refetch();
  }, [lang]);

  return (
    <>
      <div className="mb-6 border-b border-gray-200 pb-2">
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <h3 className="-mx-2 flow-root">
                <Disclosure.Button className="flex w-full items-center justify-between bg-white p-2 text-gray-400 hover:text-gray-500">
                  <span className="font-semibold text-gray-900">{t('LINKS')} </span>
                  <span className="ml-6 flex items-center">
                    {open ? (
                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </h3>
              {links && (
                <Disclosure.Panel className="mb-2 rounded-lg border border-gray-300 px-2 py-4">
                  {links.data.map((link) => (
                    <a
                      key={link.attributes.text}
                      href={link.attributes.href}
                      className="block break-words px-2 py-1 text-sm font-semibold text-gray-600 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.attributes.text}
                    </a>
                  ))}
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};

export default LinksCollapse;
