import { Transition, Dialog } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { PrimaryButton } from '../../Buttons';
import { useShoppingCartOverlay } from '../../../providers/ShoppingCartOverlayProvider';
import { useMutation } from 'react-query';
import acceptFreeCartItemTerms from '../../../lib/fetch/cartItems/acceptFreeCartItemTerms';
import { ICartItem } from '../../../lib/fetch/cartItems';
import { useAuth } from '../../../providers/AuthProvider';
import { AlertType, useAlert } from '../../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';

interface IFreeItemsDialog {
  cartItem?: ICartItem;
}

const FreeItemsDialog = (props: IFreeItemsDialog) => {
  const { cartItem } = props;
  const [{ token }] = useAuth();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const termsText =
    'SCONTO NATURA (o Cessione a Titolo di Sconto). Si prende atto della gestione promozionale tra la Cati Spa ed il cliente secondo quanto presente nella descrizione delle promozioni stesse e visibile all’atto dell’acquisto: Esempio ARTICOLO X 10 PEZZI + 2 in SCONTO NATURA';

  const toggle = () => setIsOpen((v) => !v);
  const [, dispatchOverlayChange] = useShoppingCartOverlay();
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('SHOPPING_CART');
  const [accept, setAccept] = useState<boolean>(false);

  useEffect(() => {
    if (cartItem) {
      const { isTermsAcceptedForGiftProduct } = cartItem;

      if (!isTermsAcceptedForGiftProduct) toggle();
    }
  }, [cartItem]);

  const { mutate, isLoading } = useMutation('acceptFreeCartItemTermsMutation', acceptFreeCartItemTerms, {
    onSuccess: () => {
      toggle();
      setAccept(false);
      dispatchOverlayChange({ open: true });
    },
    onError: () => {
      dispatchAlertChange({ type: AlertType.Error, open: true, message: t('TERMS_AGREEMENT_ERROR') });
    },
  });

  const onAccept = () => {
    if (cartItem) mutate({ id: cartItem.id, token: String(token) });
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" static onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  {t('TERMS_FOR_GIFT_PRODUCTS')}
                </Dialog.Title>
                <Dialog.Description>
                  <div className="mt-5 text-sm text-gray-700">{termsText ?? 'cxasx'}</div>
                </Dialog.Description>

                <div className="flex my-5">
                  <div className="flex h-5 items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                      onChange={() => setAccept(!accept)}
                      checked={accept}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      className="font-medium text-gray-700"
                      dangerouslySetInnerHTML={{ __html: 'Accettare per presa visione' }}
                      onClick={() => setAccept(!accept)}
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <PrimaryButton text="Conferma" onClick={onAccept} disabled={!accept || isLoading} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FreeItemsDialog;
