import { IAddress } from '../interfaces';
import { ICart } from '../fetch/carts';
import { IOrder } from '../fetch/orders';

interface IGetPayPalOrder {
  addresses: IAddress[];
  deliveryAddressId: number;
  cart?: ICart | null;
  description: string;
  order?: IOrder;
  orderId: string;
  totalAmount: string;
}

interface IGetShippingAddress {
  addresses: IAddress[];
  deliveryAddressId: number;
  order?: IOrder;
}

enum PayPalItemCategory {
  PhysicalGoods = 'PHYSICAL_GOODS',
}

enum PayPalItemCurrencyCode {
  Euro = 'EUR',
}

const getRawShippingAddress = ({ addresses, deliveryAddressId, order }: IGetShippingAddress) => {
  let shippingAddress: Omit<IAddress, 'id'>;
  if (order) {
    const { attributes } = order;
    const { ord_ship_address } = attributes;
    const { data: orderShippingAddressData } = ord_ship_address;
    const { attributes: orderShippingAddressAttributes } = orderShippingAddressData;
    shippingAddress = { ...orderShippingAddressAttributes };
  } else shippingAddress = (addresses || []).find(({ id }) => id && id === deliveryAddressId)!;
  return shippingAddress;
};

const getShippingAddress = ({ addresses, deliveryAddressId, order }: IGetShippingAddress) => {
  const shippingAddress = getRawShippingAddress({
    addresses,
    deliveryAddressId,
    order,
  });
  return (
    shippingAddress && {
      shipping: {
        name: {
          full_name: shippingAddress.name || '',
        },
        address: {
          address_line_1: (shippingAddress.addressFirstLine || '').substring(0, 299),
          address_line_2: (shippingAddress.addressSecondLine || '').substring(0, 299),
          admin_area_2: (shippingAddress.municipality || '').substring(0, 119),
          admin_area_1: (shippingAddress.province || '').substring(0, 299),
          postal_code: (shippingAddress.postalCode || '').substring(0, 59),
          country_code: shippingAddress.country || '',
        },
      },
    }
  );
};

interface IGetItems {
  cart?: ICart | null;
  order?: IOrder;
}

interface IPayPalItem {
  category: PayPalItemCategory;
  name: string;
  quantity: string;
  sku: string;
  tax: {
    currency_code: PayPalItemCurrencyCode;
    value: string;
  };
  unit_amount: {
    currency_code: PayPalItemCurrencyCode;
    value: string;
  };
}

interface IPayPalItemWithDiscountAndShipping extends IPayPalItem {
  discount: number;
  shipping: number;
}

const getItems = ({ cart, order }: IGetItems): IPayPalItemWithDiscountAndShipping[] => {
  if (order) {
    const { attributes } = order;
    const { order_items } = attributes;
    const { data: orderItemsData = [] } = order_items;
    return orderItemsData.map(({ attributes: orderItemsAttributes }) => {
      return {
        name: (orderItemsAttributes.productName || orderItemsAttributes.productCode || '').substring(0, 126),
        unit_amount: {
          currency_code: PayPalItemCurrencyCode.Euro,
          value: orderItemsAttributes.price.toFixed(2),
        },
        quantity: orderItemsAttributes.quantity.toFixed(0),
        tax: {
          currency_code: PayPalItemCurrencyCode.Euro,
          value: (orderItemsAttributes.taxes || 0).toFixed(2),
        },
        category: PayPalItemCategory.PhysicalGoods,
        sku: (orderItemsAttributes.productCode || '').substring(0, 126),
        discount: orderItemsAttributes.discount || 0,
        shipping: orderItemsAttributes.shipping || 0,
      };
    });
  }
  const cart_items = cart?.cart_items ?? [];
  return cart_items.map((cartItem) => {
    return {
      name: (cartItem.product.description || cartItem.product.code || '').substring(0, 126),
      unit_amount: {
        currency_code: PayPalItemCurrencyCode.Euro,
        value: cartItem.price.toFixed(2),
      },
      quantity: cartItem.quantity.toFixed(0),
      tax: {
        currency_code: PayPalItemCurrencyCode.Euro,
        value: (cartItem.taxes || 0).toFixed(2),
      },
      category: PayPalItemCategory.PhysicalGoods,
      sku: (cartItem.product.code || '').substring(0, 126),
      discount: cartItem.discount || 0,
      shipping: cartItem.shipping || 0,
    };
  });
};

const getAmountBreakdown = (items: IPayPalItemWithDiscountAndShipping[], shipping?: number) => {
  return (
    items && {
      item_total: {
        currency_code: PayPalItemCurrencyCode.Euro,
        value: (items || [])
          .reduce((acc, { quantity, unit_amount }) => {
            return acc + (+quantity * +unit_amount.value || 0);
          }, 0)
          .toFixed(2),
      },
      tax_total: {
        currency_code: PayPalItemCurrencyCode.Euro,
        value: (items || [])
          .reduce((acc, { quantity, tax }) => {
            return acc + (+quantity * +tax.value || 0);
          }, 0)
          .toFixed(2),
      },
      shipping: {
        currency_code: PayPalItemCurrencyCode.Euro,
        value:
          typeof shipping === 'number'
            ? shipping.toFixed(2)
            : (items || [])
                .reduce((acc, { quantity, shipping }) => {
                  return acc + (+quantity * shipping || 0);
                }, 0)
                .toFixed(2),
      },
      shipping_discount: {
        currency_code: PayPalItemCurrencyCode.Euro,
        value: (0).toFixed(2),
      },
      discount: {
        currency_code: PayPalItemCurrencyCode.Euro,
        value: (items || [])
          .reduce((acc, { quantity, discount }) => {
            return acc + (+quantity * discount || 0);
          }, 0)
          .toFixed(2),
      },
    }
  );
};

export const getPayPalOrder = ({
  addresses,
  cart,
  orderId,
  deliveryAddressId,
  description,
  order,
  totalAmount,
}: IGetPayPalOrder) => {
  const itemsWithDiscountAndShipping: IPayPalItemWithDiscountAndShipping[] | undefined = getItems({ cart, order });
  const items: IPayPalItem[] | undefined =
    itemsWithDiscountAndShipping &&
    itemsWithDiscountAndShipping.map((item) => ({
      ...item,
      discount: undefined,
      shipping: undefined,
    }));
  const { shipping: cartShipping } = cart || {};
  return {
    purchase_units: [
      {
        amount: {
          breakdown: getAmountBreakdown(itemsWithDiscountAndShipping, cartShipping || 0),
          value: totalAmount,
        },
        custom_id: (orderId || '').substring(0, 126),
        description: (description || '').substring(0, 126),
        items,
        ...getShippingAddress({ addresses, deliveryAddressId, order }),
      },
    ],
  };
};

interface IValidateShippingAddress {
  addresses: IAddress[];
  deliveryAddressId: number;
  order?: IOrder;
  paypalShippingAddress: {
    city: string;
    country_code: string;
    postal_code: string;
    state: string;
  };
}

export const validatePayPalShippingAddress = ({
  addresses,
  deliveryAddressId,
  order,
  paypalShippingAddress,
}: IValidateShippingAddress) => {
  const shippingAddress = getRawShippingAddress({
    addresses,
    deliveryAddressId,
    order,
  });
  if (!shippingAddress) return false;
  const { country, municipality, postalCode, province } = shippingAddress;
  const { city, country_code, postal_code, state } = paypalShippingAddress;
  if ((country || '').toUpperCase() !== (country_code || '').toUpperCase()) return false;
  if ((municipality || '').toUpperCase() !== (city || '').toUpperCase()) return false;
  if ((postalCode || '').toUpperCase() !== (postal_code || '').toUpperCase()) return false;
  if (province && province.toUpperCase() !== (state || '').toUpperCase()) return false;
  return true;
};
